import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import AvatarIcon from "../AvatarIcon";
import { Spinner  } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { format } from "date-fns";

const InlineUser = ({ userId }) => {
  console.log("user id" , userId)
  const userRef = db.collection("users").doc(userId);

  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    userRef.get().then((response) => {
      if (response) {
        console.log(response.data());
        setUserInfo(response.data());
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {userInfo ? (
        <>
            <div style={{overflow: 'hidden'}} >
              <div className="float-left mr-3">
                <AvatarIcon
                  width={100}
                  height={100}
                  imageSource={userInfo.profilePic}
                />
              </div>

              <div className="mt-3">
              <Link to={`users/${userInfo.uid}`} ><h5 style={{color: 'black'}}> {userInfo.name} </h5></Link>
                {userInfo.isAdmin ? (
                  <span className="float-bottom">Indigo Admin</span>
                ) : <span className="float-bottom">Member since {format(userInfo.dateCreated.toDate() , "MMM y")} </span>}
              </div>
            </div>
          <hr></hr>
        </>
      ) : (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </div>
  );
};

export default InlineUser;
