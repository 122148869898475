import React, { useEffect, useReducer, useState } from "react";
import { Card, Row, Container, Col, Button, Alert, Spinner } from "react-bootstrap";
import GenreCard from "../GenreCard";
import { genres } from "../genreList";
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

const UpdateGenre = () => {
  const { currentUser } = useAuth(); // We are destructuring the data from AuthContext
  const [userGenreList, setUserGenreList] = useState();
  
  const userId = currentUser.uid;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Fetch what the user already has and render the UI appropriately
  useEffect(() => {
    const userRef = db.collection("users").doc(currentUser.uid);

    const unsubscribe = () => {
      userRef.onSnapshot((snap) => {
        snap.data().listOfGenres.forEach((preSelectedGenre) => {
          genres.forEach((genre) => {
            if (preSelectedGenre === genre.id) {
              genre.checked = true;
            }
          });
        });
        setUserGenreList(snap.data().listOfGenres);
      });
    };

    unsubscribe();

    return () => unsubscribe();
  }, []);

  const handleClick = () => {
    setLoading(true)
    const listOfGenres = [];
    state.forEach((genre) => {
      if (genre.checked) {
        listOfGenres.push(genre.id);
      }
    });

    db.collection("users")
      .doc(userId)
      .set({ listOfGenres }, { merge: true })
      .then(() => {
        setLoading(false)
        setSuccess("Genre Updated.");
        setTimeout(() => {
          setSuccess("");
      }, 2000);
      })
      .catch(() => {
        setLoading(false)
        setError(
          "Something went wrong... Please contact one of the Indigo Admins for assistance."
        );
      });
  };

  function reducer(state, action) {
    switch (action.type) {
      case "select":
        const list = state.map((genre) =>
          genre.id === action.genre.id
            ? { ...genre, checked: !genre.checked }
            : genre
        );
        return list;
      default:
        alert(
          "Sorry! Something went wrong... Please contact one of the Indigo Admins for assistance."
        );
    }
  }

  const [state, dispatch] = useReducer(reducer, genres);

  return (
    <>
      {/* <Card.Title className="mt-3">What genres do you listen to?</Card.Title> */}
      <Card className="mt-3">
        
        <Card.Body>
        {success && <Alert variant="success">{success}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
          <div className="px-3 mb-3">
            <h3 className="mt-3 d-inline">Genres You Listen To</h3>

            <Button disabled={loading} className="float-right" onClick={handleClick}>
              Update Genres {" "}
                  {loading && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
            </Button>
          </div>
          <Container>
            <Row>
              {state.map((genre) => (
                <Col xs="3">
                  <GenreCard genre={genre} dispatch={dispatch} />
                </Col>
              ))}
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default UpdateGenre;
