import React from "react";
import Login from "../../components/AuthCards/Login";
import { Row, Col, Container, Image } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext"
import { Redirect } from "react-router-dom";

import indigoIcon from "../../assets/indigo-scales-icon.png";
const LoginPage = () => {
  const { currentUser } = useAuth();

  return (
    currentUser ? <Redirect to="/"/> : <div>
    <Container className='mt-4'>
      <Row>
        <Col className="my-auto mx-auto text-center">
          <Image style={{width:300 , height:300, marginLeft: 'auto', marginRight: 'auto', display:'block'}} src={indigoIcon}></Image>
          <h1>Welcome to Indigo Scales</h1>
        </Col>
        <Col>
          <Login />
        </Col>
      </Row>
    </Container>
  </div> 
    
  );
};

export default LoginPage;
