import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
const HelpCard = () => {
  return (
    <Card >
      <Card.Header>Need Help?</Card.Header>

      <Card.Body>
        <ListGroup>
          <ListGroup.Item>
            <Card.Title>
              Looking to sell/buy equipment or just casual banter?{" "}
            </Card.Title>
            <Card.Text>
              Head over to the{" "}
              <Link to="/feed">
                <strong>Feed</strong>
              </Link> {" "}
              tab!
            </Card.Text>
          </ListGroup.Item>

          <ListGroup.Item>
            <Card.Title>Wondering what are our upcoming events?</Card.Title>
            <Card.Text>
              <Link to="/events">
                <strong>Events</strong>
              </Link>{" "}
              is what you're looking for
            </Card.Text>
          </ListGroup.Item>

          <ListGroup.Item>
            <Card.Title>Want to book the SCAPE Jam Room?</Card.Title>
            <Card.Text>
              Go to the  {" "}
              <Link to="/jamroom">
                <strong>Jam Room</strong>
              </Link>{" "}
              tab!
            </Card.Text>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>

      <Card.Footer className="text-muted">Feel free to contact any of the Indigo Admins if you have any questions!</Card.Footer>
    </Card>
  );
};

export default HelpCard;
