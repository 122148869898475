import React from 'react'

const XPTier = ({level}) => {
    let icon;

    if (level === "Missing Snek") {
        icon = "❔"
    } else if (level === "Aspiring Snek") {
        icon = "😄"
    } else {
        icon = "🐍"
    }
    
    return (
        <div className="mb-3">
            <h3>{icon} {level}</h3>
        </div>
    )
}

export default XPTier
