import React from 'react'
import EventsContainer from "../../components/EventsComp/EventsContainer"
import { Container } from 'react-bootstrap'
import "./events.css"
const EventsPage = () => {
    return (
        <Container className="fill">
            <h1>Upcoming Events</h1>
            <EventsContainer/>
        </Container>
    )
}

export default EventsPage
