import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import XPTier from "./XPTier";
import {useAuth} from "../../contexts/AuthContext"

const XPInfo = ({ xpPoints, viewProgressBar }) => {
  const {  isAdmin } = useAuth();
  
  const formattedXp = xpPoints % 100;

  const [xpTier, setXpTier] = useState();
  const [xpLabel, setXpLabel] = useState();

  useEffect(() => {
    if (xpPoints < 100) {
      setXpTier("Missing Snek");
    } else if (xpPoints >= 100 && xpPoints < 200) {
      setXpTier("Aspiring Snek");
    } else if (xpPoints >= 200 && xpPoints <= 300) {
      setXpTier("Champion Snek");
    }

    setXpLabel(`${xpPoints}/${Math.ceil(xpPoints / 100) * 100}`);

    
    
  }, [xpPoints]);

  return (
    <div className='xp-info'>
      {xpPoints !== null && <XPTier level={xpTier} />}

      {isAdmin || viewProgressBar ? (
        formattedXp === 0 ? (
          xpPoints === 0 ? (
            <ProgressBar
              now={100}
              label={"Attend/Help out at events to get points!"}
            ></ProgressBar>
          ) : (
            <ProgressBar now={100} label={xpLabel}></ProgressBar>
          )
        ) : (
          <ProgressBar animated label={xpLabel} now={formattedXp} />
        )
      ) : null}
    </div>
  );
};

export default XPInfo;
