import React from "react";
import { Modal, Button } from "react-bootstrap";
import format from "date-fns/format";

const DatePopupModal = ({ show, handleClose, eventInfo, handleDelete }) => {
  //! TO-DO: PROVIDE DELETE OPTION

  const { title, email, start, end, id } = eventInfo;
  return (
    <div>
      <Modal centered show={show} handleClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>{format(start, "PPp")} </strong> <br /> to <br />
          <strong>{format(end, "PPp")} </strong>
        </Modal.Body>
        <Modal.Body>{email}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleDelete(title, id);
            }}
          >
            Delete Booking
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DatePopupModal;
