import React, { useState } from "react";
import { Form, Alert, Button , Card} from "react-bootstrap";
import { Formik } from "formik";
import { db } from "../../firebase";

const TestAdmin = () => {
  const [error, setError] = useState("");
  const [feedback, setFeedback] = useState('');
  const userRef = db.collection("users");

  return (
    <Card>
      <Card.Body>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={async (values, actions) => {
            const { email } = values;

            if (!email) { return }
          try {
            setError("");
            setFeedback("");
            let query = userRef.where("email", "==", email);
            query.get().then(querySnapshot => {
                if (querySnapshot.empty) {
                    setError("User does not exist")
                    return 
                }
                querySnapshot.forEach(documentSnapshot => {
                  const uid = documentSnapshot.id;

                  db.collection('users').doc(uid).set({isAdmin: true} , {merge: 'true'})
                  setFeedback(`${email} is now an admin!`)
                });
              })
          } catch {
            console.log("error");
            setError("Error adding user as admin. User might not exist.");
          }
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Label>Make someone an admin</Form.Label>
            <Form.Control
              type="text"
              name="makeAdmin"
              placeholder="User's email"
              {...formik.getFieldProps("email")}
            />
            <Button type="submit">Submit</Button>
          </Form>
        )}
      </Formik>

      {error && <Alert variant="danger">{error}</Alert>}
      {feedback && <Alert variant="success">{feedback}</Alert>}
      </Card.Body>
    </Card>
  );
};

export default TestAdmin;
