import { Container, Jumbotron, Image, Row, Col } from "react-bootstrap";
import IndigoIcon from "../../assets/indigo-scales-icon.png";
import React from "react";

const PublicHomeHeader = () => {
  return (
    <Jumbotron>
      <Container fluid className="d-flex justify-content-center">
        <Row>
          <Col className="d-flex align-items-center" xs={8}>
            <div>
              <h1>Indigo Scales</h1>
            <h3>BE BOLD, BE BRAVE</h3>
            {/* <p>Log in or Sign-Up to get started</p> */}
            </div>
          </Col>
          <Col xs={4}>
            <Image height={300} src={IndigoIcon} />
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
};

export default PublicHomeHeader;
