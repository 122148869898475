import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const NavigationBar = () => {
  const { currentUser , isAdmin, setIsAdmin} = useAuth();
  // console.log(isAdmin)
  

  return (
    <Navbar id="nav-bar" variant="light" className="color-nav" expand="lg">
      <Navbar.Brand href="/">Indigo Scales</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {currentUser && <Nav.Link href="/feed">Feed</Nav.Link>}

          {/* <Nav.Link href="/about-us">About Us </Nav.Link> */}

          {currentUser &&  <Nav.Link href="/events">Events</Nav.Link>}

          {currentUser ? <Nav.Link href="/jamroom">Jam Room</Nav.Link> : null}
        </Nav>

        <div className="nav-special-links"></div>
        {isAdmin ?   <ManageMembersLink  /> : null}
        {currentUser ? <ProfileLinks toggleAdmin={setIsAdmin} /> : <LoginSignup />}
      </Navbar.Collapse>
    </Navbar>
  );
};

const LoginSignup = () => {
  return <Nav.Link href="/login">Login/Signup</Nav.Link>;
};


const ManageMembersLink = () => {
return <Nav.Link href="/manage-members">Manage Members</Nav.Link>
}


const ProfileLinks = ( { toggleAdmin } ) => {
  const { logout } = useAuth();

  const history = useHistory();

  async function handleLogout() {
    // setError("");
    try {
      await logout();
      toggleAdmin(false)
      history.push("/login");
    } catch (e) {
      // setError("Failed to logout");
      console.log(e)
      console.log("error logging out");
    }
  }
  return (
    <div>
      <NavDropdown title="My Profile" id="basic-nav-dropdown">
        <NavDropdown.Item href="/users/me">View My Profile</NavDropdown.Item>
        <NavDropdown.Item href="/update-profile">
          Update Profile
        </NavDropdown.Item>

        <NavDropdown.Divider />
        <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
      </NavDropdown>
    </div>
  );
};

export default NavigationBar;
