import React, { useReducer } from "react";
import { Card, Row, Container, Col, Button } from "react-bootstrap";
import GenreCard from "./GenreCard";
import { genres } from "./genreList";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

const GenreSetup = () => {
  const { currentUser } = useAuth();
  const userId = currentUser.uid;
  const history = useHistory();

  const handleClick = () => {
    const listOfGenres = [];
    state.forEach((genre) => {
      if (genre.checked) {
        listOfGenres.push(genre.id);
      }
    });

    db.collection("users")
      .doc(userId)
      .set({ listOfGenres }, { merge: true })
      .then(() => history.push("/profile-setup/instrument"))
      .catch(() => {
        alert(
          "Something went wrong... Please contact one of the Indigo Admins for assistance."
        );
      });
  };

  function reducer(state, action) {
    switch (action.type) {
      case "select":
        const list = state.map((genre) =>
          genre.id === action.genre.id
            ? { ...genre, checked: !genre.checked }
            : genre
        );
        return list;
      default:
        alert("Sorry! Something went wrong... Please contact one of the Indigo Admins for assistance.")
    } 
  }

  const [state, dispatch] = useReducer(reducer, genres);

  return (
    <>
      {/* <Card.Title className="mt-3">What genres do you listen to?</Card.Title> */}
      <Card>
        <Card.Body>
        <h3 className="mt-3">What genres do you listen to?</h3>
          <Container>
            <Row className="row-no-gutters">
              {state.map((genre) => (
                <Col className="d-flex col-lg-2">
                  <GenreCard genre={genre} dispatch={dispatch} />
                </Col>
              ))}
            </Row>
            <Button className="float-right" onClick={handleClick}>
              Next
            </Button>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default GenreSetup;
