import React from "react";
import InstrumentSetup from "../../components/AuthCards/InstrumentSetup";
import { Row, Col, Container, Image, ProgressBar } from "react-bootstrap";
import indigoIcon from "../../assets/indigo-scales-icon.png";

const InstrumentSetupPage = () => {
  const now = 40;
  return (
    <div>
      <Container className="mt-4">
        <Row>
          {/* <Col className="my-auto mx-auto text-center"> */}
            {/* <Image
              style={{
                width: 300,
                height: 300,
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              src={indigoIcon}
            ></Image> */}
            {/* <h1>Welcome to Indigo Scales</h1> */}
          {/* </Col> */}
          <Col>
            <div className="mb-4">
              <ProgressBar animated now={now} />
            </div>
            <InstrumentSetup />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InstrumentSetupPage;
