import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext"
import React ,  { useEffect } from "react";

const data = {
  name: "Los Angeles",
  state: "CA",
  country: "USA",
};

const UploadData = () => {
  const { currentUser } = useAuth();

  useEffect(() => {
    currentUser.providerData.forEach(function (profile) {
      console.log("Sign-in provider: " + profile.providerId);
      console.log("  Provider-specific UID: " + profile.uid);
      console.log("  Name: " + profile.displayName);
      console.log("  Email: " + profile.email);
      console.log("  Photo URL: " + profile.photoURL);
    })})
  const handleUpload = async (e) => {
    e.preventDefault();

      // eslint-disable-next-line
    const res = await db
      .collection("users")
      .add(data)
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  };

  return (
    <div>
      <button onClick={handleUpload}>Press Me</button>
    </div>
  );
};

export default UploadData;
