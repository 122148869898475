import React, {  useEffect, useState } from "react";
import { db } from "../../firebase";
import MemberTable from "./MemberTable";

const MemberTableCard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  var mounted = true;

//   const getData = async () => {
//     var userRef;

//     try {
//       userRef = db.collection("users");
//       const snapshot = await userRef.get();
//       var tempArray = [];
//       snapshot.forEach((doc) => {
//         tempArray.push(doc.data());
//       });
//       setData(tempArray);
//       setLoading(false);
//       // console.log(typeof data)
//     } catch (e) {
//       console.log(e);
//     }
//   };

  useEffect( () => {
    async function fetchData() {
      var userRef;

      try {
        userRef = db.collection("users");
        const snapshot = await userRef.get();
        var tempArray = [];
        snapshot.forEach((doc) => {
          tempArray.push(doc.data());
        });
        setData(tempArray);
        setLoading(false);
        // console.log(typeof data)
      } catch (e) {
        console.log(e);
      }
    }

    if (mounted) {
        fetchData()

    }


    return function cleanup() {
      // eslint-disable-next-line
      mounted = false;
    };
  }, []);
  return (
    <div>{data.length !== 0 && !loading ? <MemberTable data={data} setData={setData} /> : <h1>"Loading..."</h1>}</div>
  );
};

export default MemberTableCard;
