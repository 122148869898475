import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { db } from "../firebase";
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  function checkAdminFromUser(user) {
    if (!user) {
      setIsAdmin(false);
      return;
    }

    const userRef = db.collection("users").doc(user.uid);
    userRef.get().then((response) => {
      if (response) {
        setIsAdmin(response.data().isAdmin);
      }
    });

    // userRef.onSnapshot((snap) => {
    //   const response = snap.data();
    //   if (response) {
    //     setIsAdmin(response.isAdmin);
    //   }
    // });

    // const unsubscribe = () =>
    //   userRef.onSnapshot((snap) => {
    //     const response = (snap.data());
    //     setIsAdmin(response.isAdmin)
    //     return () => unsubscribe();

    //   });
  }

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return auth.currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return auth.currentUser.updatePassword(password);
  }

  useEffect(() => {
    // onAuthStateChanged allows us to find out when the user has been set
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      checkAdminFromUser(user);

      setLoading(false);
    });

    // unsubscribe ->
    // onAuthStateChanged returns a method that when called, unsubscribes the onAuthStateChanged event

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    isAdmin,
    setIsAdmin,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
