import React, { useState } from "react";
import { Card, Form, Button, Alert, Spinner } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";

const Login = () => {
  const [error, setError] = useState("");
  const history = useHistory();

  const { login } = useAuth(); // We are destructuring the data from AuthContext

  return (
    <div>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Login</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Formik
            initialValues={{ email: "", password: "" }}
            // validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const { email, password } = values;
              try {
                setError("");
                await login(email, password);
                setSubmitting(false);
                // resetForm();
                history.push("/");
              } catch {
                setError("Failed to login");
              }
            }}
          >
            {(formik) => (
              <>
                {/* {console.log(formik)} */}
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email :</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email"
                      {...formik.getFieldProps("email")}
                    />
                    {/* {formik.touched.email && } */}

                    {formik.touched.email && formik.errors.email ? (
                      <Alert variant="danger">{formik.errors.email}</Alert>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="formPassword">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Password"
                      {...formik.getFieldProps("password")}
                    />

                    {formik.touched.password && formik.errors.password ? (
                      <Alert variant="danger">{formik.errors.password}</Alert>
                    ) : null}
                  </Form.Group>

                  <div className="d-flex justify-content-center">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      Login{" "}
                      {formik.isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Link to="/forgot-password"><Button variant="link" size="sm">
                    
                      Forgot your password?
                    </Button></Link>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <div className="w-100 text-center mt-2">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
    </div>
  );
};

export default Login;

/*

<div>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Login</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>

            <Button disabled={loading} type="submit" className="w-100">
              Login
            </Button>
          </Form>

          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgot your password? </Link>
          </div>
        </Card.Body>
      </Card>

      <div className="w-100 text-center mt-2">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
    </div>


    */
