import React , { useState} from 'react'
import {useAuth} from "../../contexts/AuthContext"
import { db } from "../../firebase"
import { format } from "date-fns"

export const TestDate = () => {
    const { currentUser } = useAuth();
    const [userInfo, setUserInfo] = useState();

    const onClick = (e) => {
        console.log('clicked')
        const userRef = db.collection('users').doc(currentUser.uid);
        const unsubscribe = () => {
            userRef.onSnapshot((snap) => {
                setUserInfo(snap.data().dateCreated.toDate())
                console.log(userInfo)
            });

        }
        unsubscribe();
        return () => unsubscribe();
    }
    return (
        <div>
            <button onClick={onClick}>Print Date</button>

            <pre>{userInfo && format(userInfo, 'dd MMM yyyy')}</pre>
            
        </div>
    )
}
