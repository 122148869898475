import React from "react";
import EventCard from "./EventCard";
import { Modal, Button } from "react-bootstrap";
const ViewEventModal = ({ data, show, handleClose, deleteEvent }) => {
  return (
    <Modal
      show={show}
      handleClose={() => {
        handleClose();
      }}

      onHide={handleClose}
    >
      <EventCard
        handleViewClose={() => handleClose()}
        deleteEvent={deleteEvent}
        data={data}
      />

      <Button
        // style={{maxWidth:"300px"}}
        variant="secondary"
        onClick={() => {
          handleClose();
        }}
      >
        Close
      </Button>
    </Modal>
  );
};

export default ViewEventModal;
