import { db } from "../../firebase";
import firebase from "firebase/app";

export const handleUserGoing = (currentUser, eventId) => {
  // * Firestore references
  const userRef = db.collection("users").doc(currentUser.uid);
  const eventRef = db.collection("events").doc(eventId);
  // 1. Add event to list of events on USER side
  /* Data structure:
          eventsUpcoming: {
            going: [array of event IDs]
          }
    
        */
  return userRef
    .update({
      "eventsUpcoming.going": firebase.firestore.FieldValue.arrayUnion(eventId),
      "eventsUpcoming.maybe": firebase.firestore.FieldValue.arrayRemove(
        eventId
      ),
      "eventsUpcoming.notGoing": firebase.firestore.FieldValue.arrayRemove(
        eventId
      ),
    })
    .then(() => {
      eventRef.update({
        "eventInterest.goingUsers": firebase.firestore.FieldValue.arrayUnion(
          currentUser.uid
        ),
        "eventInterest.maybeUsers": firebase.firestore.FieldValue.arrayRemove(
          currentUser.uid
        ),
        "eventInterest.notGoingUsers": firebase.firestore.FieldValue.arrayRemove(
          currentUser.uid
        ),
      });
    });
};



export const handleUserMaybe = (currentUser, eventId) => {
    // * Firestore references
 const userRef = db.collection("users").doc(currentUser.uid);
 const eventRef = db.collection("events").doc(eventId);
 // 1. Remove event from list of events on USER side
 /* Data structure:
         eventsUpcoming: {
           going: [array of event IDs]
         }
   
       */
 return userRef
   .update({
     "eventsUpcoming.going": firebase.firestore.FieldValue.arrayRemove(
       eventId
     ),
     "eventsUpcoming.maybe": firebase.firestore.FieldValue.arrayUnion(
       eventId
     ),
     "eventsUpcoming.notGoing": firebase.firestore.FieldValue.arrayRemove(
       eventId
     ),
   })
   .then(() => {
// 1. Remove user from list of users on EVENTS side
 /* Data structure:
         eventInterest: {
           goingUsers: [array of user IDs]
         }
   
       */


     eventRef.update({
       "eventInterest.goingUsers": firebase.firestore.FieldValue.arrayRemove(
         currentUser.uid
       ),
       "eventInterest.maybeUsers": firebase.firestore.FieldValue.arrayUnion(
         currentUser.uid
       ),
       "eventInterest.notGoingUsers": firebase.firestore.FieldValue.arrayRemove(
         currentUser.uid
       ),
     });
   });
}





export const handleUserNotgoing = (currentUser, eventId) => {
    // * Firestore references
    const userRef = db.collection("users").doc(currentUser.uid);
    const eventRef = db.collection("events").doc(eventId);
    // 1. Remove event from list of events on USER side
    /* Data structure:
            eventsUpcoming: {
              going: [array of event IDs]
            }
      
          */
    return userRef
      .update({
        "eventsUpcoming.going": firebase.firestore.FieldValue.arrayRemove(
          eventId
        ),
        "eventsUpcoming.maybe": firebase.firestore.FieldValue.arrayRemove(
          eventId
        ),
        "eventsUpcoming.notGoing": firebase.firestore.FieldValue.arrayUnion(
          eventId
        ),
      })
      .then(() => {
    // 1. Remove user from list of users on EVENTS side
    /* Data structure:
            eventInterest: {
              goingUsers: [array of user IDs]
              ...
            }
      
          */
    
    
        eventRef.update({
          "eventInterest.goingUsers": firebase.firestore.FieldValue.arrayRemove(
            currentUser.uid
          ),
          "eventInterest.maybeUsers": firebase.firestore.FieldValue.arrayRemove(
            currentUser.uid
          ),
          "eventInterest.notGoingUsers": firebase.firestore.FieldValue.arrayUnion(
            currentUser.uid
          ),
        });
      });
    }


    
export const handleClearChoices = (currentUser, eventId) => {
  // * Firestore references
  const userRef = db.collection("users").doc(currentUser.uid);
  const eventRef = db.collection("events").doc(eventId);
  // 1. Remove event from list of events on USER side
  /* Data structure:
          eventsUpcoming: {
            going: [array of event IDs]
          }
    
        */
  return userRef
    .update({
      "eventsUpcoming.going": firebase.firestore.FieldValue.arrayRemove(
        eventId
      ),
      "eventsUpcoming.maybe": firebase.firestore.FieldValue.arrayRemove(
        eventId
      ),
      "eventsUpcoming.notGoing": firebase.firestore.FieldValue.arrayRemove(
        eventId
      ),
    })
    .then(() => {
// 1. Remove user from list of users on EVENTS side
  /* Data structure:
          eventInterest: {
            goingUsers: [array of user IDs]
          }
    
        */


      eventRef.update({
        "eventInterest.goingUsers": firebase.firestore.FieldValue.arrayRemove(
          currentUser.uid
        ),
        "eventInterest.maybeUsers": firebase.firestore.FieldValue.arrayRemove(
          currentUser.uid
        ),
        "eventInterest.notGoingUsers": firebase.firestore.FieldValue.arrayRemove(
          currentUser.uid
        ),
      });
    });
};





