import React from "react";
import Signup from "../../components/AuthCards/Signup";
import { Row, Col, Container, Image } from "react-bootstrap";
import indigoIcon from "../../assets/indigo-scales-icon.png";
import { useAuth } from "../../contexts/AuthContext"

import { Redirect } from "react-router-dom";

const LoginPage = () => {
  const { currentUser } = useAuth();

  return (
    currentUser ? <Redirect to="/"/> :
    <div>
      <Container className='mt-4'>
        <Row>
          <Col className="my-auto mx-auto text-center">
            <Image style={{width:300 , height:300, marginLeft: 'auto', marginRight: 'auto', display:'block'}} src={indigoIcon}></Image>
            <h1>Welcome to Indigo Scales</h1>
          </Col>
          <Col>
            <Signup />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;
