import React, { useState } from "react";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { Form, Button, Spinner } from "react-bootstrap";

const BioSetupCard = () => {
  const { currentUser } = useAuth();
  const userId = currentUser.uid;
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  return (
    <div>
      <Formik
        initialValues={{
          bio: "",
        }}
        onSubmit={(values, actions) => {
          setLoading(true);

          // const { bio } = values;
          db.collection("users")
            .doc(userId)
            .set(values, { merge: true })
            .then(() => history.push("/"))
            .catch(() => {
              setLoading(false);
              alert(
                "Something went wrong... Please contact one of the Indigo Admins for assistance."
              );
            });
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="You can always change this later"
                {...formik.getFieldProps("bio")}
              />
            </Form.Group>

            <Button className="float-right" type="submit">
              Setup my profile{" "}
              {loading && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BioSetupCard;
