import { Modal, Form, Button, Container, Image, Alert} from "react-bootstrap";
import React, { useState, useReducer, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import { db } from "../../firebase";
import { storage } from "../../firebase";
import DateTimePicker from "react-datetime-picker";
const EditEventModal = ({ handleViewClose, show, handleClose, eventInfo }) => {
  const { eventDate, eventImage, id } = eventInfo;
  const eventsRef = db.collection("events");

  const [startTime, setStartTime] = useState();

  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    // Need to set the start time
    if (eventDate) {
      setStartTime(eventDate.toDate());
    }

    // Need to set the image preview
    setImagePreview(eventImage);
    // eslint-disable-next-line
  }, []);

  const handleImageUpload = (eventId) => {
    const storageRef = storage.ref();
    const eventImagesRef = storageRef.child(`eventImages/${eventId}`);

    eventImagesRef.put(file).on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        setError(
          "Sorry... Something went wrong. Please try again or contact one of the Indigo Admins."
        );
      },
      async () => {
        //* Get download url of uploaded image
        const url = await eventImagesRef.getDownloadURL();
        db.collection("events")
          .doc(eventId)
          .set({ eventImage: url }, { merge: true });

        setImagePreview(null);
        setFile(null);
        console.log("image uploaded to storage");
      }
    );
  };

  const updateEvent = (eventId) => {
    try {
      console.log(startTime)
      if (startTime) {
        eventsRef
          .doc(id)
          .set(
            {
              ...state,
              eventDate: startTime,
            },
            { merge: true }
          )
          .then((value) => {
            if (file) {
              handleImageUpload(eventId);
            }
            handleClose();
            handleViewClose();
          });
      } else {
        eventsRef
          .doc(id)
          .set(
            {
              ...state,
              eventDate: null,
            },
            { merge: true }
          )
          .then((value) => {
            if (file) {
              handleImageUpload(eventId);
            }
            handleClose();
            handleViewClose();
          });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  const changeHandler = async (e) => {
    let selected = e.target.files[0];
    setError("");

    const allowedTypes = ["image/png", "image/jpeg"];

    if (selected && allowedTypes.includes(selected.type)) {
      const resizedImage = await resizeFile(selected);
      const url = URL.createObjectURL(resizedImage);
      // console.log('IMGE', image)
      // console.log(url)
      //* Set the preview to what the user uploaded
      setImagePreview(url);
      //* Set the File to be uploaded
      //   setFile(selected);
      setFile(resizedImage);

      console.log(resizedImage);
    } else {
      setFile(null);
      setError("Invalid file type (only png or jpeg allowed)");
    }
  };

  const reducer = (state, action) => {
    if (action.type === "eventNameChange") {
      return { ...state, eventName: action.payload };
    }

    if (action.type === "eventInfoChange") {
      return { ...state, eventInfo: action.payload };
    }
  };
  const [state, dispatch] = useReducer(reducer, eventInfo);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header>
        <Modal.Title>Edit Event</Modal.Title>
      </Modal.Header>

      <Modal.Body>
      {error ? <Alert variant="danger">{error}</Alert> : null}

        <Form>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Event Name"
              value={state.eventName}
              onChange={(e) => {
                dispatch({ type: "eventNameChange", payload: e.target.value });
              }}
            />
          </Form.Group>
          <Form.Label>Date</Form.Label>
          <br />
          <DateTimePicker
            className="mb-3"
            format="dd-MM-y h:mm a"
            hourPlaceholder="hour"
            minutePlaceholder="minutes"
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            minDate={new Date()}
            disableClock={false}
            value={startTime}
            onChange={(e) => {
              console.log(typeof e);
              console.log(e);
              setStartTime(e);
            }}
          />
          {imagePreview ? (
            <Container>
              <div className="text-center">
                <Image
                  style={{ width: "100%", maxWidth: "1080px" }}
                  src={imagePreview}
                />
              </div>
              <Button
                variant="warning"
                onClick={() => {
                  setImagePreview(null);
                  setFile(null);
                }}
                className="my-3 d-block my-auto mx-auto"
              >
                Remove Image
              </Button>
            </Container>
          ) : null}

          {/*  ******* FORM COMPONENTS ******* */}

          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              name="eventInfo"
              placeholder="Event Description"
              value={state.eventInfo}
              onChange={(e) => {
                dispatch({ type: "eventInfoChange", payload: e.target.value });
              }}
            />
          </Form.Group>

          <Button variant="secondary" className="align-text-bottom">
            <Form.File>
              <Form.File.Input
                onChange={changeHandler}
                id="postImage"
                style={{ display: "none" }}
                isValid
              />
              <Form.File.Label
                htmlFor="postImage"
                style={{
                  display: "inline-block",
                }}
              >
                Add Image
              </Form.File.Label>
            </Form.File>
          </Button>
        </Form>

        <Button
          variant="primary"
          className="float-right ml-3"
          onClick={() => updateEvent(id)}
        >
          Update Event
        </Button>
        <Button
          variant="secondary"
          className="float-right"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default EditEventModal;
