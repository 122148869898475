import React from "react";
import UpdateProfile from "../../components/AuthCards/UpdateProfileComps/UpdateProfile";
import {  Container  } from "react-bootstrap";

const UpdateProfilePage = () => {
  return (
    <Container>
      <div className="mx-auto" style={{ width: "800px" }}>
        <UpdateProfile />
      </div>
    </Container>
  );
};

export default UpdateProfilePage;
