import React, { useState } from "react";
import { TabContainer, Tab, Tabs, Form, Button, Alert } from "react-bootstrap";
// import { useAuth } from "../../../contexts/AuthContext";
import { useAuth } from "../../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import updateProfileSchema from "../../../schemas/updateProfileSchema";
import { Formik } from "formik";
import UpdateBasicInformation from "./UpdateBasicInformation";
import GenreSetup from "../GenreSetup";
import UpdateGenre from "./UpdateGenre";
import UpdateInstruments from "./UpdateInstruments";
const UpdateProfile = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { currentUser, updateEmail, updatePassword } = useAuth(); // We are destructuring the data from AuthContext

  return (
    <div >
      <Tabs defaultActiveKey="BasicInformation" id="uncontrolled-tab-example">
        <Tab eventKey="BasicInformation" title="Basic Information">
          <UpdateBasicInformation />
        </Tab>
        <Tab eventKey="profile" title="Genres">
          <UpdateGenre />
        </Tab>

        <Tab eventKey="UpdateInstruments" title="Instruments">
          <UpdateInstruments/>
        </Tab>
      </Tabs>

    
    </div>
  );
};

export default UpdateProfile;
