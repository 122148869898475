import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { Card, ListGroup } from "react-bootstrap";
import InlineUser from "../FeedComp/InlineUser";

const CommonGenreUsersCard = () => {
  const { currentUser } = useAuth();

  const [userGenre, setUserGenre] = useState();
  const [commonUsers, setCommonUsers] = useState();
  const allUserRef = db.collection("users");
  const userRef = db.collection("users").doc(currentUser.uid);

  useEffect(() => {
    // Get the current users genre list
    userRef.get().then((response) => {
      // console.log("Genres liked by this user: ", response.data().listOfGenres);
      const { listOfGenres } = response.data();
      if (listOfGenres) {
        const index = Math.floor(Math.random() * listOfGenres.length);
        const randomGenrePicked = listOfGenres[index];
        // console.log("The random genre picked", listOfGenres[index]);
        setUserGenre(randomGenrePicked);

        allUserRef
          .limit(5)
          .where("listOfGenres", "array-contains-any", [randomGenrePicked])
          .where("uid", "!=", currentUser.uid)
          .get()
          .then((_commonUsers) => {
            //   console.log(commonUsers.docsdata())
            console.log(_commonUsers.empty);
            if (!_commonUsers.empty) {
              setCommonUsers(_commonUsers);
              _commonUsers.docs.forEach((doc) => {
                console.log(
                  `People who also like : ${randomGenrePicked}`,
                  doc.data()
                );
              });
            }
          });
      }
    });
  }, []);

  return (
    <div>
      {console.log("common users", commonUsers)}
      {commonUsers ? (
        <Card className="mt-3">
          <Card.Header>
            People who also like <strong>{userGenre}</strong>
          </Card.Header>
          <Card.Body>
            <ListGroup variant="flush">
              {commonUsers.docs.map((user) => {
                return (
                  <ListGroup.Item>
                    <InlineUser userId={user.id} />
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Card.Body>
        </Card>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default CommonGenreUsersCard;
