import React, { useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { COLUMNS } from "./columns";
import { Table, Button, Alert } from "react-bootstrap";
import GlobalFilter from "./GlobalFilter";
import { db } from "../../firebase";

const MemberTable = ({ data, setData }) => {
  const columns = useMemo(() => COLUMNS, []);
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState();
  const [showAlertError, setShowAlertError] = useState();
  const [alertText, setAlertText] = useState("");
  //   const data = JSON.parse(receivedData)

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {

    // db.collection('users').doc

    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          console.log("new value", value)
          if (old[rowIndex][columnId] === value) {
            console.log("same");
            return { ...old[rowIndex] };
          }

          const uid = old[rowIndex]["uid"];


          db.collection("users")
            .doc(uid)
            .update({ [columnId]: value })
            .then(() => {
              setAlertText(
                `Updated ${old[rowIndex]["name"]}'s ${columnId} Successfully from ${old[rowIndex][columnId]} to ${value}`
              );
              setShowAlertSuccess(true);
              setTimeout(() => {
                setShowAlertSuccess(false);
                setAlertText("");
              }, 2000);
            })
            .catch((e) => {
              alert(e);
              setAlertText("Something went wrong while updating...");
              setShowAlertError(true);
              setTimeout(() => {
                setShowAlertError(false);
                setAlertText("");
              }, 2000);
              return {
                ...old[rowIndex],
              };
            });
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }

        return row;
      })
    );
  };

  // In useTable, we poss in an object. It returns an instance of a table
  // The object must indicate the columns and mock_data.
  // But mock_data and columns must be memoized.
  const {
    getTableProps, // Function that needs to be destructured in the table tag
    getTableBodyProps,
    headerGroups, // Group of Headers is an array that requires us to map

    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,

    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex } = state;

  return (
    <>
      <GlobalFilter
        filter={globalFilter}
        setFilter={setGlobalFilter}
        className="mb-3"
      />

      {showAlertSuccess && <Alert variant="success">{alertText}</Alert>}
      {showAlertError && <Alert variant="danger">{alertText}</Alert>}
      <Table striped bordered hover className="mt-3" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps}>
              {headerGroup.headers.map((column, index) => (
                <th key={index} {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr key={index} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <div>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <Button
        variant="primary"
          className="m-3"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          Previous
        </Button>
        <Button variant="primary" onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
      </div>
    </>
  );
};

export default MemberTable;
