import React, { useEffect } from 'react'
import TestAddMod from "./TestAddMod"
import { useAuth } from "../../contexts/AuthContext"


const PrintUser = () => {
    const { currentUser } = useAuth();

    useEffect(() => {
        console.log('useEffect')
    }, [])
    return (
        <div>
        <TestAddMod />
            
            <pre>{JSON.stringify(currentUser, null, 4)}</pre>
        </div>
    )
}

export default PrintUser
