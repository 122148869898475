import React from 'react'
import {Image} from 'react-bootstrap'
import defaultIcon from "../assets/default-profile-icon.jpg"
const AvatarIcon = ({imageSource, width, height}) => {
    return (
        <div className="mb-4 d-inline">
            <Image
            width={width || 144}
            height={height || 144}
            src={imageSource ? imageSource : defaultIcon}
            roundedCircle
            
          />
        </div>
    )
}

export default AvatarIcon
