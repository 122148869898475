import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Alert, Card, Form, Button, Spinner } from "react-bootstrap";
import { db } from "../../../firebase";

import { useAuth } from "../../../contexts/AuthContext";

const UpdateBio = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [bio, setBio] = useState("");
  const userId = currentUser.uid;

  useEffect(() => {
    const userRef = db.collection("users").doc(currentUser.uid);

    userRef.get().then((doc) => {
      console.log(doc.data().bio);
      if (doc.exists) {
        setBio(doc.data().bio);
      }
    });
  }, []);
  return (
    <Card className="my-3">
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Card.Body>
        <div className="text-center">
          <h2>Bio</h2>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            bio,
          }}
          onSubmit={(values, actions) => {
            setLoading(true);
            setError("");

            // const { bio } = values;
            db.collection("users")
              .doc(userId)
              .set(values, { merge: true })
              .then(() => {
                setSuccess("Bio Updated Successfully");
                setLoading(false);
                setTimeout(() => {
                  setSuccess("");
                }, 2000);
              })
              .catch(() => {
                setLoading(false);
                setError(
                  "Something went wrong... Please contact one of the Indigo Admins for assistance."
                );
              });
          }}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="You can always change this later"
                  {...formik.getFieldProps("bio")}
                />
              </Form.Group>

              <div className="d-flex justify-content-center">
                <Button className="text-center" type="submit">
                  Update Bio{" "}
                  {loading && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default UpdateBio;
