import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { db } from "../../firebase";
import EventsCardSubBody from "./EventsCardSubBody";
import ViewEventModal from "../EventsComp/ViewEventModal";

const getEventInfo = async (eventId) => {
  const eventsRef = db.collection("events");

  const eventInfo = await eventsRef.doc(eventId).get();
  return { ...eventInfo.data(), id: eventId };
};

const UpcomingEventsCard = ({ data }) => {
  const [viewModalContent, setViewModalContent] = useState();

  const [showViewEventModal, setViewEventModal] = useState(false);
  const handleViewClose = () => setViewEventModal(false);
  const handleViewShow = () => setViewEventModal(true);

  const { going } = data;
  const [goingEventInfoList, setGoingEventInfoList] = useState([]);

  const triggerViewPopup = (data, id) => {
    handleViewShow();
    setViewModalContent({ ...data, id });
  };
  useEffect(() => {
    if (going) {
      going.forEach((eventId) => {
        getEventInfo(eventId).then((response) => {
          console.log(response);
          setGoingEventInfoList((prevValue) => {
            return [...prevValue, response];
          });
        });
        // setEventInfoList((prev) => {
        //     return [...prev, eventData]
        // })
      });
    }

    return () => {
      setGoingEventInfoList([]);
    };
  }, []);
  // We need to fetch the event info based on the event ID
  return (
    <>
      <Card>
        <Card.Header>Your Upcoming Events</Card.Header>
        <Card.Body>
          {goingEventInfoList ? (
            goingEventInfoList.length > 0 ? (
              goingEventInfoList.map((event) => {
                // console.log(event.id);

                return (
                  <EventsCardSubBody
                    triggerViewPopup={triggerViewPopup}
                    key={event.id}
                    data={event}
                  />
                );
              })
            ) : (
              <div className="text-center">
                <p>You have no upcoming events.</p>
              </div>
            )
          ) : null}
        </Card.Body>
      </Card>

      <ViewEventModal
        data={viewModalContent}
        show={showViewEventModal}
        handleClose={handleViewClose}
      />
    </>
  );
};

export default UpcomingEventsCard;
