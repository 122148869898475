import React, { useState } from "react";
import {
  Card,
  Button,
  Form,
  Spinner,
  Alert,
  Image,
  Container,
} from "react-bootstrap";
import { db } from "../../firebase";
import { storage } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { Formik } from "formik";
import postSchema from "../../schemas/postSchema";
import Resizer from "react-image-file-resizer";

const FeedInputBox = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState();
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  const changeHandler = async (e) => {
    let selected = e.target.files[0];
    setError("");

    const allowedTypes = ["image/png", "image/jpeg"];

    if (selected && allowedTypes.includes(selected.type)) {
      const resizedImage = await resizeFile(selected);
      const url = URL.createObjectURL(resizedImage);
      // console.log('IMGE', image)
      // console.log(url)
      //* Set the preview to what the user uploaded
      setImagePreview(url);
      //* Set the File to be uploaded
      //   setFile(selected);
      setFile(resizedImage);

      console.log(resizedImage);
    } else {
      setFile(null);
      setError("Invalid file type (only png or jpeg allowed)");
    }
  };

  const handleImageUpload = (postId) => {
    const storageRef = storage.ref();
    const postImagesRef = storageRef.child(`postImages/${postId}`);

    postImagesRef.put(file).on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        setError(
          "Sorry... Something went wrong. Please try again or contact one of the Indigo Admins."
        );
      },
      async () => {
        //* Get download url of uploaded image
        const url = await postImagesRef.getDownloadURL();
        db.collection("posts")
          .doc(postId)
          .set({ postImageUrl: url }, { merge: true });

        setImagePreview(null);
        setFile(null);
        console.log("image uploaded to storage");
      }
    );
  };

  return (
    <div>
        <h1>Create a new post</h1>

      <Card className="my-4 shadow">
        <Card.Body className="feed-input-box">
          <Formik
            initialValues={{
              postTitle: "",
              postContent: "",
              postTag: "casual",
            }}
            validationSchema={postSchema}
            onSubmit={(values, { resetForm }) => {
              setError("");
              setLoading(true);
              const { postTitle, postContent, postTag } = values;
              console.log("Post tagged: ", postTag);
              try {
                var datePosted = new Date();

                /*
                                Info We Need:
                                - Date Posted
                                - Posted By Who (User ID)
                                - Title
                                - Content
                            */
                db.collection("posts")
                  .add({
                    postTitle,
                    postContent,
                    datePosted,
                    postTag,
                    postedByUserId: currentUser.uid,
                  })
                  .then((value) => {
                    console.log(value.id); // Post id
                    if (file) {
                      handleImageUpload(value.id);
                    }
                    resetForm();
                    setLoading(false);
                    setShowAlert(true);
                    setTimeout(() => {
                      setShowAlert(false);
                    }, 2000);
                  })
                  .catch((e) => {
                    console.log(e);
                    setError(
                      "Sorry. Something went wrong... Please try again later or contact one of the admins"
                    );
                    setLoading(false);
                  });
              } catch (e) {
                setLoading(false);

                console.log(e);
              }
            }}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="postTitle"
                    placeholder="Title"
                    {...formik.getFieldProps("postTitle")}
                  />
                </Form.Group>
                {formik.errors.postTitle && formik.touched.postTitle ? (
                  <Alert variant="danger">{formik.errors.postTitle}</Alert>
                ) : null}

                {file ? (
                  <Container>
                    <div className="text-center">
                      <Image
                        style={{ width: "100%", maxWidth: "1080px" }}
                        src={imagePreview}
                      />
                    </div>
                    <Button
                      variant="warning"
                      onClick={() => {
                        setImagePreview(null);
                        setFile(null);
                      }}
                      className="my-3 d-block my-auto mx-auto"
                    >
                      Remove Image
                    </Button>
                  </Container>
                ) : null}

                <Form.Group>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="postContent"
                    placeholder="Make a new post"
                    {...formik.getFieldProps("postContent")}
                  />
                </Form.Group>
                {formik.errors.postContent && formik.touched.postContent ? (
                  <Alert variant="danger">{formik.errors.postContent}</Alert>
                ) : null}

                <Form.Group>
                  <Form.Label>Tag</Form.Label>
                  <Form.Control
                    as="select"
                    name="postTag"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultValue="Choose..."
                    {...formik.getFieldProps("postTag")}
                  >
                    <option value="casual">Casual</option>
                    <option value="announcement">Announcement</option>
                    <option value="connect">Looking to connect!</option>
                    <option value="shopping">Sell/Buy</option>
                  </Form.Control>
                </Form.Group>

                {error && <Alert variant="danger">{error}</Alert>}

                <div>
                  <Button
                    variant="primary"
                    className="float-right"
                    size="md"
                    type="submit"
                  >
                    Post{" "}
                    {loading && (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                  {/* <Button variant='secondary' className='float-right mr-3'>Attach an Image</Button> */}

                  <div className="justify-content-md-center mt-3">
                    {/* <Form inline className="mr-3 pl-3">
                      <Form.File size="60" id="postImage" label="Add Image" custom />
                    </Form> */}
                    <Button variant="secondary" className="align-text-bottom">
                      <Form.File >
                        <Form.File.Input
                          onChange={changeHandler}
                          id="postImage"
                          style={{ display: "none", cursor: 'pointer' }}
                          isValid
                        />
                        <Form.File.Label
                          htmlFor="postImage"
                          style={{
                            display: "inline-block",
                            cursor: 'pointer'
                          }}
                        >
                          Add Image
                        </Form.File.Label>
                      </Form.File>
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <div>
        {showAlert && (
          <Alert variant="success">
            <Alert.Heading>Nice!</Alert.Heading>Now everyone can see your post!
          </Alert>
        )}
      </div>
    </div>
  );
};

export default FeedInputBox;
