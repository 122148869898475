import React from "react";
import { Card } from "react-bootstrap";

const JamRoomHelpCard = () => {
  return (
    <div>
      <Card className="mb-3">
        <Card.Body>
          <Card.Title>How to book?</Card.Title>

          <Card.Text>
            Please take note that the room is only available <strong> every Saturday from
            1-6pm.</strong>
            <ol style={{listStyle:'inside' , paddingLeft: 0}}>
              <li>Choose your Saturday timeslot (between 1-6pm)</li>
              <li>Enter your BAND NAME + Contact Number</li>
              <li>Submit your booking</li>
            </ol>
          </Card.Text>

          <Card.Subtitle className="mb-2 text-muted ">Tip: It's easier to book by going into "Week" view and dragging over your desired timeslot</Card.Subtitle>
        </Card.Body>
      </Card>
    </div>
  );
};

export default JamRoomHelpCard;
