import * as Yup from "yup";



function equalTo(ref , msg ) {
    return Yup.mixed().test({
      name: 'equalTo',
      exclusive: false,
      // eslint-disable-next-line
      message: msg || '${path} must be the same as ${reference}',
      params: {
        reference: ref.path,
      },
      test: function(value ) {
        return value === this.resolve(ref);
      },
    });
  }
  Yup.addMethod(Yup.string, 'equalTo', equalTo);



const updateProfileSchema = Yup.object().shape({
  email: Yup.string()
  .required("Please provide your email")
    .email("Must be a valid email address")
    .max(100, "Email must be less than 100 characters"),

  password: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),

  passwordConfirm: Yup.string()
    .equalTo(Yup.ref("password"), "Passwords must match")
});

export default updateProfileSchema;
