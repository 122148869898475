import React from "react";
import GenreSetup from "../../components/AuthCards/GenreSetup";
import { Row, Col, Container, Image, ProgressBar } from "react-bootstrap";
import indigoIcon from "../../assets/indigo-scales-icon.png";
import "./auth.css";
const GenreSetupPage = () => {
  const now = 20;
  return (
    <div>
      <Container className="mt-4">
        <Row>
          {/* <Col className="my-auto mx-auto text-center"> */}
          {/* <Image
              style={{
                width: 300,
                height: 300,
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              src={indigoIcon}
            ></Image>
            <h1>Welcome to Indigo Scales</h1> */}
          {/* </Col> */}
          <Col>
            <div className="mb-4">
              <ProgressBar
                size="lg"
                animated
                now={now}
                // label={`Welcome aboard!`}
              />
            </div>

            <GenreSetup />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GenreSetupPage;
