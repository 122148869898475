import React, { useState } from "react";
import { Card, Form, Button, Alert, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import { useAuth } from "../../../contexts/AuthContext";
import updateProfileSchema from "../../../schemas/updateProfileSchema";
import { db } from "../../../firebase";
import { Link, useHistory } from "react-router-dom";
const UpdateEmailPassword = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [loading, setLoading] = useState(false);
  const { currentUser, updateEmail, updatePassword } = useAuth(); // We are destructuring the data from AuthContext

  const updateEmailDatabase = async (email) => {
    const userRef = db.collection("users").doc(currentUser.uid);
    return userRef.update({ email });
  };
  return (
    <Card className="mt-3">
      <Card.Body>
        <h2 className="text-center mb-4">Email/Password</h2>

        {success && <Alert variant="success">{success}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}

        <Formik
          initialValues={{
            email: currentUser.email,
            password: "",
            passwordConfirm: "",
          }}
          validationSchema={updateProfileSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const { email, password } = values;
            const promises = [];
            setLoading(true);
            setError("");

            if (email !== currentUser.email) {
              promises.push(updateEmail(email));
              promises.push(updateEmailDatabase(email));
            }

            if (password) {
              promises.push(updatePassword(password));
            }

            Promise.all(promises)
              .then(() => {
                setSubmitting(false);
                //
                setLoading(false);
                setSuccess("Email/Password Updated.");
                setTimeout(() => {
                    setSuccess("");
                }, 2000);
               
              })
              .catch(() => {
                resetForm();
                setError("Failed to update account. Try logging in again.");
                setLoading(false);
              });

            // .finally(() => {
            //   setLoading(false);
            // });
          }}
        >
          {(formik) => (
            <>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    required
                    {...formik.getFieldProps("email")}
                  />
                  {formik.errors.email ? (
                    <Alert variant="danger">{formik.errors.email}</Alert>
                  ) : null}
                </Form.Group>
                <Form.Group id="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Leave blank to keep the same"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <Alert variant="danger">{formik.errors.password}</Alert>
                  ) : null}
                </Form.Group>
                <Form.Group id="password-confirm">
                  <Form.Label>Password Confirmation</Form.Label>
                  <Form.Control
                    type="password"
                    name="passwordConfirm"
                    {...formik.getFieldProps("passwordConfirm")}
                  />
                  {formik.touched.passwordConfirm &&
                  formik.errors.passwordConfirm ? (
                    <Alert variant="danger">Passwords do not match</Alert>
                  ) : null}
                </Form.Group>
                <div className="d-flex justify-content-center">
                <Button disabled={loading} type="submit">
                  Update Email/Password {" "}
                  {loading && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default UpdateEmailPassword;
