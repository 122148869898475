
import * as Yup from "yup";


const postSchema = Yup.object().shape({

    postTitle: Yup.string().required("Your post needs a title!"),
    postContent: Yup.string().required("Did you forget to type something?")


    // name: Yup.string().required("Please tell us your name"),
  
    // telegramHandle: Yup.string().required("Please tell us your telegram handle"),
  
  
  
    // email: Yup.string()
    // .required("Please provide your email")
    //   .email("Must be a valid email address")
    //   .max(100, "Email must be less than 100 characters"),
  
    // password: Yup.string()
    //   .required("Please Enter your password")
    //   .matches(
    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    //   ),
  
    // passwordConfirm: Yup.string()
    //   .equalTo(Yup.ref("password"), "Passwords must match")
    //   .required("Required")
  });
  
  export default postSchema;
  