import { format } from "date-fns";
import React from "react";
import { Link } from 'react-router-dom'
import { db } from "../../firebase"


import { getYear } from "date-fns";


const currentYear = getYear( new Date())

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (id === "xpPoints") {
    return (
      <input
        type="number"
        className="text-center"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
  return (
    <input
      className="text-center"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export const COLUMNS = [


  {
    Header: "Name",
    accessor: "name",
    // Cell: EditableCell,
    Cell: (e) => {
      console.log(e.row.original)
      const {uid} = e.row.original
      return <Link to={`/users/${uid}`}>{e.value}</Link>
    }

  
  },


  // {
  //   Header: "ID",
  //   accessor: "uid"
  // },

  {
    Header: "Date Joined",
    accessor: "dateCreated",
    Cell: ({ value }) => {
      if (value) {
        return format(new Date(value.toDate()), "dd/MM/yyyy");
      } else {
        return "Date Unknown";
      }
    },
  },

  {
    Header: `XP Points for ${currentYear} `,
    accessor: `xpPoints.${currentYear}`,
    Cell: EditableCell,
  },

  {
    Header: "Email",
    accessor: "email",
  },

  {
    Header: "Telegram Handle",
    accessor: "telegramHandle",
    Cell: (e) => <a href={`https://t.me/${e.value}`}> {e.value} </a>,
  },

  {
    Header: "Member Status",
    accessor: "isAdmin",
    Cell: ({ value }) => {
      if (value) return "Admin";
      else return "Member";
    },
  },
];
