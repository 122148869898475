import React, { useState } from "react";
import { db } from "../../firebase";

import { storage } from "../../firebase";
import { ProgressBar } from "react-bootstrap"

const UploadImage = () => {
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };
  const handleImageUpload = () => {
    //   const uploadTask = storage.ref(`images/${image.name}`).put(image)
      // const storageRef = storage.ref(image.name)
      const storageRef = storage.ref()
      const collectionRef = db.collection('images')
      storageRef.child('images').put(image).on("state_changed",
      snapshot => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          setProgress(progress)
      },
      error => {
          console.log(error);
      }, async () => {
        const url = await storageRef.getDownloadURL()
        collectionRef.add({ url })
        setUrl(url);
      })
  };

  console.log("image: " , image)
  return (
    <div>
        <ProgressBar now={progress}  label={`${progress}%`} max="100" />
      <input type="file" onChange={handleChange} className="m-3" />
      <button onClick={handleImageUpload}>upload an image</button>
      <img src={url} alt="firebase" />
    </div>
  );
};

export default UploadImage;
