import React from "react";
import { Card } from "react-bootstrap";
import { format } from "date-fns";


const EventsCardSubBody = ({ data , triggerViewPopup}) => {
  const { eventName, eventInfo, eventDate , id} = data;
//   const formattedEventDate = format(eventDate.toDate(), "PPp")
  return (
    <Card className="event-thumbnail" onClick={() => triggerViewPopup(data, id)}>
      <Card.Body >
        <Card.Title>{eventName}</Card.Title>
        {eventDate && <Card.Subtitle>{format(eventDate.toDate(), "PPp")}</Card.Subtitle>}
        <Card.Text>{eventInfo.length > 100 ? eventInfo.substring(0,100) + "..." : eventInfo}</Card.Text>

        {/* {eventInfo} */}
      </Card.Body>
    </Card>
  );
};

export default EventsCardSubBody;
