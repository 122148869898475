import React, { useEffect, useState } from "react";
import { Card, Alert, Row, Col, Container, ListGroup } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import AvatarIcon from "../AvatarIcon";
import InstrumentItem from "./InstrumentItem";
import XPInfo from "./XPInfo";
import { getYear } from "date-fns";
import GenreItem from "./GenreItem"
const Profile = () => {
  const [error] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const { currentUser } = useAuth();
  const currentYear = getYear(new Date());

  useEffect(() => {
    const userRef = db.collection("users").doc(currentUser.uid);
    const unsubscribe = () =>
      userRef.onSnapshot((snap) => {
        setUserInfo(snap.data());
      });
    unsubscribe();
    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* <Card style={{width: '18rem'}}>
        <Card.Body style={{margin: 'auto'}}> */}
      <div>
        <Container className="mt-3">
          {error && <Alert variant="danger">{error}</Alert>}

          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Container className="mb-3">
                    <Row>
                      <Col>
                        {userInfo.profilePic && (
                          <AvatarIcon imageSource={userInfo.profilePic} />
                        )}
                      </Col>

                      <Col>
                        <div style={{ margin: "auto" }} className="pt-4">
                          <h1> {userInfo.name}</h1>

                          <h3>{currentUser.email}</h3>
                        </div>
                      </Col>
                    </Row>
                  </Container>

                  <Card.Title>You love to:</Card.Title>
                  <ListGroup className="text-center mb-4 mt-4" variant="flush">
                    {userInfo.instrumentsPlayed && (
                      <InstrumentItem
                        instruments={userInfo.instrumentsPlayed}
                      />
                    )}
                  </ListGroup>

                  <Card.Title>You feed your ears with:</Card.Title>
                  <ListGroup className="text-center mb-4 mt-4" variant="flush">
                    {userInfo.listOfGenres && (
                      <GenreItem
                        genres = {userInfo.listOfGenres}
                      />
                    )}
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>

            <Col className="my-auto">
              <div className="text-center" >
                {userInfo.xpPoints && (
                  <XPInfo
                    xpPoints={userInfo.xpPoints[currentYear]}
                    viewProgressBar={true}
                  />
                )}

                {/* {userInfo.xpPoints  && <XPBar xpPoints={userInfo.xpPoints}/>} */}
                {/* <h1>Events Attended</h1> */}
                {/* <h1>QR Code</h1> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* // </Card> */}
    </>
  );
};

export default Profile;
