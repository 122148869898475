import React from "react";
import { ListGroup } from "react-bootstrap";
const InstrumentItem = ({ instruments }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div>
      {instruments.map((instrument) => {
        const instrumentLabelArray = instrument.split("-");
        // console.log(instrumentLabelArray)
        const instrumentLabel =
          instrumentLabelArray.length > 1
            ? instrumentLabelArray[0] + " " + 
              capitalizeFirstLetter(instrumentLabelArray[1])
            : instrument;
        return (
          <ListGroup.Item key={instrument}>{instrumentLabel}</ListGroup.Item>
        );
      })}
    </div>
  );
};

export default InstrumentItem;
