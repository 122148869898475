import React, { useState, useEffect } from "react";
import { Card, ListGroup, Button } from "react-bootstrap";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import AddRuleModal from "./AddRuleModal";

const JamRoomRulesCard = () => {
  const { isAdmin } = useAuth();

  const jamRoomRulesRef = db.collection("jamRoomRules");
  const [listOfRules, setListOfRules] = useState();
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    const unsubscribe = () => {
      jamRoomRulesRef.onSnapshot((snap) => {
        setListOfRules(snap.docs);
      });
    };

    unsubscribe();
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  const addRule = (rule) => {
    console.log("adding rule");
    return jamRoomRulesRef
      .add({
        text: rule,
      })
      .then(() => {
        console.log("Rule added sucessfully");
        handleClose();
      })
      .catch(() => {
        alert("Error adding rule");
      });
  };

  const deleteRule = (id) => {
    return jamRoomRulesRef
      .doc(id)
      .delete()
      .then("Rule deleted successfully")
      .catch(() => alert("Error deleting rule"));
  };
  return (
    <div>
      <AddRuleModal show={show} handleClose={handleClose} addRule={addRule} />
      <Card bg="warning">
        <Card.Body>
          <Card.Title className="text-center">
            Jam Room Booking Rules
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted text-center">
            Disobey at your own risk *hiss*
          </Card.Subtitle>
          <Card.Text>
            {listOfRules && (
              <ListGroup variant="flushed">
                {listOfRules.map((doc) => {
                  const { text } = doc.data();
                  return (
                    <>
                      {" "}
                      <strong>
                        <ListGroup.Item variant="secondary" key={doc.id}>{text}</ListGroup.Item>
                      </strong>
                      {isAdmin && editMode ? (
                        <Button
                          onClick={() => deleteRule(doc.id)}
                          className="mb-3"
                          variant="danger"
                          size="sm"
                        >
                          Delete
                        </Button>
                      ) : null}
                    </>
                  );
                })}
              </ListGroup>
            )}
          </Card.Text>
        </Card.Body>
        {isAdmin && editMode ? (
          <Button onClick={handleShow}>Add Rule</Button>
        ) : null}

        {isAdmin && <Button variant="secondary" onClick={() => setEditMode(!editMode)}>
          {!editMode ? "Edit Rules" : "Finish Editing Rules"}
        </Button>}
      </Card>
    </div>
  );
};

export default JamRoomRulesCard;
