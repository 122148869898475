import React, { useEffect, useReducer, useState } from "react";
import { Card, Row, Container, Col, Button, Alert, Spinner } from "react-bootstrap";
import { instrumentChoices } from "../instrumentChoices";
import { db } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import InstrumentCard from "../InstrumentCard";

const UpdateInstruments = () => {
  const { currentUser } = useAuth(); // We are destructuring the data from AuthContext
  const [userInstrumentList, setUserInstrumentList] = useState();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const userId = currentUser.uid;
  const history = useHistory();
  useEffect(() => {
    const userRef = db.collection("users").doc(currentUser.uid);

    const unsubscribe = () => {
      userRef.onSnapshot((snap) => {
        console.log(snap.data().instrumentsPlayed);

        snap.data().instrumentsPlayed.forEach((preSelectedInstrument) => {
          instrumentChoices.forEach((instrument) => {
            if (preSelectedInstrument === instrument.id) {
              instrument.checked = true;
            }
          });
        });
        setUserInstrumentList(snap.data().instrumentsPlayed);
      });
    };

    unsubscribe();

    return () => unsubscribe();
  }, []);

  const handleClick = () => {
      setLoading(true)
    const listOfInstruments = [];
    state.forEach((instrument) => {
      if (instrument.checked) {
        listOfInstruments.push(instrument.id);
      }
    });

    db.collection("users")
      .doc(userId)
      .set({ instrumentsPlayed: listOfInstruments }, { merge: true })
      .then(() => {
          setLoading(false)
        setSuccess("Instruments Updated.");
        setTimeout(() => {
            setSuccess("");
        }, 2000);
      })
      .catch(() => {
          setLoading(false)
        setError(
          "Something went wrong... Please contact one of the Indigo Admins for assistance."
        );
      });
  };

  function reducer(state, action) {
    switch (action.type) {
      case "select":
        const list = state.map((instrument) =>
          instrument.id === action.instrument.id
            ? { ...instrument, checked: !instrument.checked }
            : instrument
        );
        return list;
      default:
        alert(
          "Sorry! Something went wrong... Please contact one of the Indigo Admins for assistance."
        );
    }
  }

  const [state, dispatch] = useReducer(reducer, instrumentChoices);
  return (
    <Card>
      <Card.Body>
      {success && <Alert variant="success">{success}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="px-3 mb-3">
          <h3 className="mt-3 d-inline">Instruments You Play</h3>
          <Button disabled={loading} className="float-right" onClick={handleClick}>
            Update Instruments {" "}
            {loading && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
          </Button>
        </div>

        <Container>
          <Row>
            {state.map((instrument) => (
              <Col xs="3" className="mt-3">
                <InstrumentCard instrument={instrument} dispatch={dispatch} />
              </Col>
            ))}
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default UpdateInstruments;
