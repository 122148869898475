import React , { useState } from "react";
import UpdateAvatar from "./UpdateAvatar"
import UpdateBio from "./UpdateBio";
import UpdateEmailPassword from "./UpdateEmailPassword";


const UpdateBasicInformation = () => {
 


    
 
  return (
    <div>
        <UpdateBio/>
        <UpdateAvatar/>
        <UpdateEmailPassword/>
    </div>
  );
};

export default UpdateBasicInformation;
