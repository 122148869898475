import React, { useState } from 'react'

import Avatar from 'react-avatar-edit'


function AvatarTesting() {
    const [preview, setPreview] = useState(null);
    function onClose() {
      setPreview(null);
    }
    function onCrop(pv) {
      setPreview(pv);
    }
    // eslint-disable-next-line
    function onBeforeFileLoad(elem) {
      if (elem.target.files[0].size > 71680) {
        alert("File is too big!");
        elem.target.value = "";
      }
    }
    return (
      <div>
        <Avatar
        label="hello"
        backgroundColor= "grey"
        style = {{borderStyle: "solid"}}
          width={300}
          height={300}
          onCrop={onCrop}
          onClose={onClose}
        //   onBeforeFileLoad={onBeforeFileLoad}
          src={null}
        />
        {preview && <img src={preview} alt="Preview" />}
      </div>
    );
  }
  export default AvatarTesting;