import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { storage } from "../../../firebase";
import { useAuth } from "../../../contexts/AuthContext";
import { Form, Button, Row, Alert, Card, Spinner } from "react-bootstrap";
import AvatarIcon from "../../AvatarIcon";
import defaultIcon from "../../../assets/default-profile-icon.jpg";
import { useHistory } from "react-router-dom";

const UpdateAvatar = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { currentUser } = useAuth();
  const userId = currentUser.uid;
  const history = useHistory();

  useEffect(() => {
    const userRef = db.collection("users").doc(userId);

    userRef.get().then((doc) => {
      if (doc.exists) {
        setImagePreview(doc.data().profilePic);
      }
    });
  }, []);

  const handleImageUpload = () => {
    setLoading(true);
    //* Get a reference to the root storage
    const storageRef = storage.ref();

    //* Get reference to profileImages folder
    //! TO DO: ADD INTERMEDIARY FOLDER REFERENCING USER'S NAME
    const profileImagesRef = storageRef.child(`profileImages/${userId}`);
    const collectionRef = db.collection("users");
    profileImagesRef.put(file).on(
      "state_changed",
      (snapshot) => {
        // const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        // setProgress(progress)
      },
      (error) => {
        setError(
          "Sorry... Something went wrong. Please try again or contact one of the Indigo Admins."
        );
      },
      async () => {
        //* Get the download URL for the uploaded image
        const url = await profileImagesRef.getDownloadURL();

        //* Add it to the associated user's account
        collectionRef
          .doc(userId)
          .set({ profilePic: url }, { merge: true })
          .then(() => {
            setSuccess("Profile Picture Updated Successfully");
            setLoading(false);
            setFile(null);
            setTimeout(() => {
              setSuccess("");
            }, 2000);
          })
          .catch(() => {
            setLoading(false);

            setError(
              "Sorry... Something went wrong. Please try again or contact one of the Indigo Admins."
            );
          });
      }
    );
  };

  const changeHandler = (e) => {
    let selected = e.target.files[0];
    setError("");

    const allowedTypes = ["image/png", "image/jpeg"];

    if (selected && allowedTypes.includes(selected.type)) {
      const url = URL.createObjectURL(selected);
      console.log(url);
      //* Set the preview to what the user uploaded
      setImagePreview(url);

      //* Set the File to be uploaded
      setFile(selected);
    } else {
      setFile(null);
      setError("Invalid file type (only png or jpeg allowed)");
    }
  };
  return (
    <Card>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Card.Body>
        <div className="text-center">
          <h2>Change your profile picture</h2>
        </div>
        <div className="d-flex justify-content-center">
          <AvatarIcon
            // style={{width:"100%" , height: "auto", objectFit: 'cover', maxHeight: '100vh'}}
            width={200}
            height={200}
            imageSource={imagePreview ? imagePreview : defaultIcon}
            roundedCircle
          />
        </div>
        {error && (
          <Alert className="mt-3" variant="danger">
            {error}
          </Alert>
        )}

        <Row className="justify-content-md-center mt-3">
          <Form>
            <Form.File id="upload-avatar" custom>
              <Form.File.Input isValid onChange={changeHandler} />
              <Form.File.Label>
                {file ? file.name.substring(0, 30) : "Upload Profile Picture"}
              </Form.File.Label>
            </Form.File>
          </Form>
        </Row>

        <Row className="justify-content-md-center mt-3">
          <Button disabled={!file} onClick={handleImageUpload}>
            Update Profile Picture{" "}
            {loading && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default UpdateAvatar;
