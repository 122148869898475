import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import UpcomingEventsCard from "./UpcomingEventsCard";
import InterestedEventsCard from "./InterestedEventsCard";
import HelpCard from "./HelpCard";
import CommonGenreUsersCard from "./CommonGenreUsersCard";
import Guitarist from "../../assets/home-assets/guitarist-1.png";
const PrivateHomeHeader = () => {
  const { currentUser } = useAuth();
  const userRef = db.collection("users").doc(currentUser.uid);
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    userRef.get().then((response) => {
      // console.log(response.data());
      setUserInfo(response.data());
    });
  }, []);

  return userInfo ? (
    <Container className="mt-4">
      <Row className="align-items-center text-center">
        <Col xs={6}>
          <div>
          <h1 className="d-inline"> Welcome {userInfo.name}</h1>

          </div>
        </Col>
        <Col>
          <Image
            className="rounded mx-auto d-block float-right"
            src={Guitarist}
            width="500"
          />

        </Col>
      </Row>

      <Row>
        <Col>
          <HelpCard />
        </Col>

        <Col>
          <UpcomingEventsCard data={userInfo.eventsUpcoming} />

          <InterestedEventsCard data={userInfo.eventsUpcoming} />
          <CommonGenreUsersCard />
        </Col>
      </Row>
    </Container>
  ) : (
    <div>Loading...</div>
  );
};

export default PrivateHomeHeader;
