import React from "react";
import { Card } from "react-bootstrap";
import { format } from "date-fns";

const EventThumbnail = ({ id , data, triggerViewPopup }) => {
  const { eventDate, eventInfo, eventName, eventImage } = data;
  return (
    <Card
      className="my-3 shadow event-thumbnail"
      onClick={() => triggerViewPopup(data, id)}
      style={{ minWidth: "300px", maxWidth: "300px" }}
    >
      {eventImage ? (
        <Card.Img
          variant="top"
          style={{ display: "block" }}
          className="ml-auto mr-auto"
          src={eventImage}
        />
      ) : null}
      <Card.Body>
        <h2>{eventName}</h2>
        <Card.Subtitle>
          {" "}
          {eventDate && format(new Date(eventDate.toDate()), "PPp")}
        </Card.Subtitle>
        <Card.Text>{eventInfo.length > 100 ? eventInfo.substring(0,100) + "..." : eventInfo}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default EventThumbnail;
