import React , { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import jazzImage from "../../assets/jazz.png"

const InstrumentCard = ({instrument, dispatch}) => {
   const [image, setImage] = useState();

   const loadImage = (imageName) => {
     import("../../assets/instrument-assets/svg/" + "instrument-" + imageName + ".svg").then(
       (image) => {
         console.log(image);
         setImage(image.default);
       }
     );
   };
 
   useEffect(() => {
     const imageFileName = instrument.id.charAt(0).toLowerCase() + instrument.id.slice(1);
 
     loadImage(imageFileName);
   }, []);
  return (
    <Card
      onClick={() => dispatch({ type: "select", instrument })}
      bg={instrument.checked ? "info" : null}
    >
      <Card.Img className="mt-3"variant="bottom" src={image} height={80} />
      <Card.Body className="text-center" key={instrument.id}>
        <Card.Title className="mt-3" >{instrument.name}</Card.Title>
      </Card.Body>
    </Card>
  );
};

export default InstrumentCard;
