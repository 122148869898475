import React from "react";
import BioSetupCard from "../../components/AuthCards/BioSetupCard";
import indigoIcon from "../../assets/indigo-scales-icon.png";
import { Row, Col, Container, Image, ProgressBar } from "react-bootstrap";

const BioSetupPage = () => {
    const now = 80;



  return (
    <div>
      <Container className="mt-4">
        <Row>
          {/* <Col className="my-auto mx-auto text-center">
            <Image
              style={{
                width: 300,
                height: 300,
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              src={indigoIcon}
            ></Image>
            <h1>Welcome to Indigo Scales</h1>
          </Col> */}
          <Col>
            <div className="mb-4">
              <ProgressBar
                size="lg"
                animated
                now={now}
                label={`Almost there!`}
              />
            </div>
            <h1>Tell us more about yourself</h1>

            <BioSetupCard />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BioSetupPage;
