import React, { useEffect, useState } from "react";
import { Card, Alert, Row, Col, Container, ListGroup } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import AvatarIcon from "../AvatarIcon";

import { Redirect, useParams } from "react-router-dom";
import XPInfo from "./XPInfo";
import { getYear } from "date-fns";
import InstrumentItem from "./InstrumentItem";
import GenreItem from "./GenreItem";

const ExternalProfile = ({ match }) => {
  const { id } = useParams();

  const [error] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const { currentUser } = useAuth();
  const currentYear = getYear(new Date());

  // const tempUserId = "HvYYDMp1KWN4rX7cXqkgCTnyCGA3"

  useEffect(() => {
    const userRef = db.collection("users").doc(id);
    const unsubscribe = () =>
      userRef.onSnapshot((snap) => {
        setUserInfo(snap.data());
      });
    unsubscribe();
    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {currentUser.uid === id ? (
        <Redirect to="/users/me" />
      ) : (
        <div>
          <Container className="mt-3">
            <h1 className="text-center mb-4">Profile</h1>
            {error && <Alert variant="danger">{error}</Alert>}

            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Container className="mb-3">
                      <Row>
                        <Col>
                          {userInfo.profilePic && (
                            <AvatarIcon imageSource={userInfo.profilePic} />
                          )}
                        </Col>

                        <Col>
                          <div style={{ margin: "auto" }} className="pt-4">
                            <h1> {userInfo.name}</h1>

                            <h3>{userInfo.email}</h3>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <Card.Title>They enjoy:</Card.Title>

                    <ListGroup
                      className="text-center mb-4 mt-4"
                      variant="flush"
                    >
                      {userInfo.instrumentsPlayed && (
                        <InstrumentItem
                          instruments={userInfo.instrumentsPlayed}
                        />
                      )}
                    </ListGroup>

                    <Card.Title>Their ears feast on:</Card.Title>
                    <ListGroup
                      className="text-center mb-4 mt-4"
                      variant="flush"
                    >
                      {userInfo.listOfGenres && (
                        <GenreItem genres={userInfo.listOfGenres} />
                      )}
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>

              <Col className="my-auto">
                <div className="text-center">
                  {userInfo.xpPoints && (
                    <XPInfo
                      xpPoints={userInfo.xpPoints[currentYear]}
                      viewProgressBar={false}
                    />
                  )}

                  {/* {userInfo.xpPoints  && <XPBar xpPoints={userInfo.xpPoints}/>} */}
                  {/* <h1>Events Attended</h1>
                <h1>QR Code</h1> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default ExternalProfile;
