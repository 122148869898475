import React, { useState, useEffect } from "react";
import { Button, ButtonToolbar, ButtonGroup } from "react-bootstrap";
import { db } from "../../firebase";
import {
  handleUserGoing,
  handleUserMaybe,
  handleUserNotgoing,
  handleClearChoices,
} from "./interestButtonsUtil";

const InterestButtons = ({ data, currentUser }) => {
  const { id } = data;
  const eventRef = db.collection("events").doc(id);

  const [userInterest, setUserInterest] = useState({
    going: false,
    maybe: false,
    notGoing: false,
  });

  const [interestCount, setInterestCount] = useState({
    goingUsers: 0,
    maybeUsers: 0,
    notGoingUsers: 0,
  });
  useEffect(() => {
    const unsubscribe = () => {
      eventRef.onSnapshot((snap) => {
        if (snap.data()) {
          const {
            goingUsers,
            maybeUsers,
            notGoingUsers,
          } = snap.data().eventInterest;
          // If the user is in the going users array
          if (goingUsers && goingUsers.includes(currentUser.uid)) {
            setUserInterest({ going: true, maybe: false, notGoing: false });

            // If the user is in the maybe users array
          } else if (maybeUsers && maybeUsers.includes(currentUser.uid)) {
            setUserInterest({ going: false, maybe: true, notGoing: false });

            // If the user is in the notGoing users array
          } else if (notGoingUsers && notGoingUsers.includes(currentUser.uid)) {
            setUserInterest({ going: false, maybe: false, notGoing: true });
          } else {
            setUserInterest({ going: false, maybe: false, notGoing: false });
          }

          // if ()
          setInterestCount({
            goingUsers: goingUsers.length,
            maybeUsers: maybeUsers.length,
            notGoingUsers: notGoingUsers.length,
          });
        }
      });
    };

    unsubscribe();
    return () => {
      unsubscribe();
    };
    //eslint-disable-next-line
  }, []);

  const triggerUserGoing = () => {
    handleUserGoing(currentUser, id).then(() => {
      return;
    });
  };

  const triggerUserMaybe = () => {
    handleUserMaybe(currentUser, id).then(() => {
      return;
    });
  };

  const triggerUserNotgoing = () => {
    handleUserNotgoing(currentUser, id);
  };

  const triggerClearChoices = () => {
    handleClearChoices(currentUser, id);
  };
  return (
    <div className="mb-3">
      <ButtonToolbar className="mr-auto ml-auto">
        <ButtonGroup className="mr-2">
          {userInterest.going ? (
            <div className="text-center">
              <Button variant="success" onClick={() => triggerClearChoices()}>
                I'm Going! 😃
                <div>{interestCount.goingUsers}</div>
              </Button>
            </div>
          ) : (
            <div className="text-center">
              <Button variant="link" onClick={() => triggerUserGoing()}>
                I'm Going! 😃
                <div style={{ color: "black" }}>
                  <strong>{interestCount.goingUsers}</strong>
                </div>
              </Button>
            </div>
          )}
        </ButtonGroup>

        <ButtonGroup className="mr-2">
          {userInterest.maybe ? (
            <div className="text-center">
              <Button variant="info" onClick={() => triggerClearChoices()}>
                I'm Interested! 🤔
                <div>{interestCount.maybeUsers}</div>
              </Button>
            </div>
          ) : (
            <div className="text-center">
              <Button variant="link" onClick={() => triggerUserMaybe()}>
                I'm Interested! 🤔
                <div style={{ color: "black" }}>
                  <strong>{interestCount.maybeUsers}</strong>
                </div>
              </Button>
            </div>
          )}
        </ButtonGroup>

        <ButtonGroup className="mr-2">
          {userInterest.notGoing ? (
            <Button variant="warning" onClick={() => triggerClearChoices()}>
              Can't make it 😢
              <div>{interestCount.notGoingUsers}</div>
            </Button>
          ) : (
            <div className="text-center">
              <Button variant="link" onClick={() => triggerUserNotgoing()}>
                Can't make it 😢
                <div style={{ color: "black" }}>
                  <strong>{interestCount.notGoingUsers}</strong>
                </div>
              </Button>
            </div>
          )}
        </ButtonGroup>
      </ButtonToolbar>
    </div>
  );
};

export default InterestButtons;
