import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { Form } from "react-bootstrap";

// filter is the value of the text input , setFIlter is the setter function
const GlobalFilter = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter);

  const onChange = useAsyncDebounce((changedValue) => {
    setFilter(changedValue || undefined);
  }, 300); // We set the value to the changed value only 0.3 second after the event was fired
  return (
    <div>
      <span>
       
        <Form.Control
        placeholder="Search by name, email, date..."
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      </span>
    </div>
  );
};


export default GlobalFilter;