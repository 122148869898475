import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import AddAdminCard from "../../components/MemberManagementComp/AddAdminCard";
import { Tab, Nav, Col, Row, Container, Spinner } from "react-bootstrap";
import MemberTableCard from "../../components/MemberManagementComp/MemberTableCard";
import RemoveAdminCard from "../../components/MemberManagementComp/RemoveAdminCard";
import "./managemembers.css"

const ManageMembersPage = () => {
  const { isAdmin } = useAuth();
  const [isUserAdmin, setIsUserAdmin] = useState(true);
  useEffect(() => {
    setIsUserAdmin(isAdmin);
    console.log(isUserAdmin);
    // eslint-disable-next-line
  }, [isAdmin]);
  return isUserAdmin ? (
    <>
      <Tab.Container id="manage-member-tab" defaultActiveKey="first">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link className="tab-item" eventKey="first">Manage Members</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="tab-item"  eventKey="second">Add/Remove Admin</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>

          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <h2>Manage Members Table</h2>

                <MemberTableCard />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Container className="mt-3">
                  <AddAdminCard/>
                  <div>
                    <hr></hr>
                  </div>
                  <RemoveAdminCard/>
                </Container>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  ) : (
    <div fluid className="d-flex justify-content-center">
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>

    </div>
  );
};

export default ManageMembersPage;
