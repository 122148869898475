import React from "react";
import PublicHomeHeader from "../components/HomeComps/PublicHomeHeader";
import HomeCarousel from "../components/HomeComps/HomeCarousel";
import { Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import PrivateHomeHeader from "../components/HomeComps/PrivateHomeHeader";

const HomePage = () => {
  const { currentUser } = useAuth();

  return currentUser ? (
    <div><PrivateHomeHeader/></div>
  ) : (
    <div>
      <PublicHomeHeader />
      <Container>
        <HomeCarousel />
      </Container>
    </div>
  );
};

export default HomePage;
