import React from 'react'
import { Container } from 'react-bootstrap'
import FeedInputBox from "../../components/FeedComp/FeedInputBox"
import FeedStream from "../../components/FeedComp/FeedStream"
import "./feed.css"
const FeedPage = () => {
    return (
        <>
        <Container>

            <FeedInputBox/>
        </Container>

        <Container>
            <FeedStream/>
        </Container>
        </>
    )
}

export default FeedPage
