import React, { useEffect, useState } from "react";
import { Card, Button  } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import InlineUser from "./InlineUser";
import UserInteraction from "./UserInteraction";
import firebase from "firebase/app";
import { db } from "../../firebase";
import { format } from "date-fns";

const Post = ({
  postTitle,
  postContent,
  postId,
  postedByUserId,
  imageUrl,
  deleteRights,
  postDate,
  deletePost,
  arrayOfLikes,
  postTag,
  //   handleLike
}) => {
  const { currentUser } = useAuth();
  const [isLiked, setIsLiked] = useState();

  const handleLike = (postId) => {
    const postsRef = db.collection("posts").doc(postId);
    postsRef
      .update({
        arrayOfLikes: firebase.firestore.FieldValue.arrayUnion(currentUser.uid),
      })
      .then(() => {
        setIsLiked(true);
      })
      .catch((e) => {
        alert(e);
      });
  };

  const handleUnlike = (postId) => {
    const postsRef = db.collection("posts").doc(postId);
    postsRef
      .update({
        arrayOfLikes: firebase.firestore.FieldValue.arrayRemove(
          currentUser.uid
        ),
      })
      .then(() => {
        setIsLiked(false);
      })
      .catch((e) => {
        alert(e);
      });
  };

  useEffect(() => {
    if (arrayOfLikes) {
      setIsLiked(arrayOfLikes.includes(currentUser.uid));
    }
    // eslint-disable-next-line
  }, [isLiked]);

  // Check whether the user is in the array of likes
  // If yes, pass a value of 'liked = true' to the like button

  return (
    <Card className="my-3 shadow-lg">
      <Card.Body>
        {/* User Here */}
        {/* <Card.Subtitle className="float-right">{format(new Date(postDate.toDate()), 'EEEE dd MMM yyyy')}</Card.Subtitle> */}
        <Card.Subtitle className="float-right">
          {format(new Date(postDate.toDate()), "PPp")}
        </Card.Subtitle>
        <InlineUser userId={postedByUserId} />
        <Card.Subtitle className="text-muted float-right">
          #{postTag}
        </Card.Subtitle>
        <Card.Title>{postTitle}</Card.Title>

        {imageUrl ? <Card.Img src={imageUrl} /> : null}
        <Card.Text>{postContent}</Card.Text>

        <div className="float-right">
          {deleteRights ? (
            <Button
              size="sm"
              variant="danger"
              onClick={() => deletePost(postId)}
            >
              Delete this post
            </Button>
          ) : postedByUserId === currentUser.uid ? (
            <Button
              size="sm"
              variant="danger"
              onClick={() => deletePost(postId)}
            >
              Delete this post
            </Button>
          ) : null}
        </div>

        {/* <div className="float-right">
          {postedByUserId == currentUser.uid ? (
            <Button
              size="sm"
              variant="danger"
              onClick={() => deletePost(postId)}
            >
              Delete this post
            </Button>
          ) : null}
        </div> */}

        <div className="mb-4 ">
          <span className="align-baseline">
            <strong>{arrayOfLikes && arrayOfLikes.length + " 👍"}</strong>
          </span>
        </div>

        <div>
          <UserInteraction
            postId={postId}
            isLiked={isLiked}
            handleLike={handleLike}
            handleUnlike={handleUnlike}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default Post;
