import React, { useEffect, useState } from "react";
import {Carousel, Image} from "react-bootstrap"
import { storage } from "../../firebase";
import { db } from "../../firebase";

const HomeCarousel = () => {
  const [contents, setContents] = useState();
  const homeImagesRef = db.collection('homeImages')


  useEffect(() => {

    const unsubscribe = () => {
        homeImagesRef.onSnapshot((snap) => {
            // console.log(snap)
            setContents(snap.docs)
            
        })
    }

    unsubscribe()
    return () => {
        unsubscribe()
    }
      
  }, []);
  return (
    <div>
      <Carousel>
          {contents && contents.map((content) => {
              // console.log(content.data())
              const {imageUrl, imageCaptionTitle, imageCaptionBody} = content.data();

              return (
                <Carousel.Item interval={3000}>
                <Image
                  className="d-block w-100"
                  src={imageUrl}
                  alt={imageCaptionTitle}
                />
                <Carousel.Caption>
                  <h3>{imageCaptionTitle}</h3>
                  {/* <p>Our annual halloween themed event</p>*/}
                  <p>{imageCaptionBody}</p>
                </Carousel.Caption>
              </Carousel.Item>
              )
          })}
      </Carousel>
    </div>
  );
};

export default HomeCarousel;
