import React, {useState} from 'react'

const TestAddMod = () => {
    const [mod, setMod] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(mod)

    }
    return (
        <div>
            <h1>Add a moderator</h1>
            <form onSubmit={handleSubmit}>
            <input value={mod} onChange={e => setMod(e.target.value)} type="text" placeholder="Enter the user email"/>
            <button type="submit">Make a mod!</button>
            </form>
        </div>
    )
}

export default TestAddMod
