import React, { useState } from "react";
import {
  Card,
  ButtonGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { format } from "date-fns";
import { useAuth } from "../../contexts/AuthContext";
import EditEventModal from "./EditEventModal";

import InterestButtons from "./InterestButtons";

const EventCard = ({ handleViewClose, data, deleteEvent }) => {
  const {
    eventDate,
    eventInfo,
    eventName,
    eventImage,
    id,
    
  } = data;

  const { currentUser, isAdmin } = useAuth();
  const [showEditEventModal, setEditEventModal] = useState(false);

  const handleEditClose = () => setEditEventModal(false);
  const handleEditShow = () => setEditEventModal(true);

  const triggerEditModal = () => {
    handleEditShow();
  };

  return (
    <>
      <Card>
        <Card.Body>
          {/* EVENT NAME AND EDIT ACTIONS */}
          <div className="mb-3">
            <h3 style={{ display: "inline-block" }}>{eventName}</h3>

            <ButtonGroup vertical className="float-right">
              {/* <Button onClick={() => {}}>Add to Calendar</Button> */}

              {isAdmin && (
                <DropdownButton
                  as={ButtonGroup}
                  variant="secondary"
                  title="Edit"
                  className="float-right"
                >
                  <Dropdown.Item onClick={() => triggerEditModal()}>
                    Edit Event Info
                  </Dropdown.Item>
                  <Dropdown.Item
                    // style={{ display: "block", alignItems: "right" }}
                    onClick={() => deleteEvent(id)}
                    variant="danger"
                  >
                    Delete Event
                  </Dropdown.Item>{" "}
                </DropdownButton>
              )}
            </ButtonGroup>
          </div>

          {/* EVENT DATE */}
          <Card.Subtitle className="mb-3">
            {" "}
            {eventDate && format(new Date(eventDate.toDate()), "PPp")}
          </Card.Subtitle>

          {/* EVENT IMAGE */}
          {eventImage ? (
            <Card.Img
              style={{ display: "block" }}
              className="ml-auto mr-auto mb-3"
              src={eventImage}
            />
          ) : null}

          {/* EVENT DESCRIPTION */}
          <Card.Text>{eventInfo}</Card.Text>
        </Card.Body>

        <div className="ml-auto mr-auto">
          <InterestButtons data={data} currentUser={currentUser} />
        </div>
      </Card>

      <EditEventModal
        handleViewClose={handleViewClose}
        show={showEditEventModal}
        handleClose={handleEditClose}
        eventInfo={data}
      />
    </>
  );
};

export default EventCard;
