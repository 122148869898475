import React , { useReducer} from "react";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import {  useHistory } from 'react-router-dom'
import { instrumentChoices } from "./instrumentChoices";

import { Formik, FieldArray } from "formik";
import {
  Form,
  Container,
  Button,
  Card,
  Col,
  Row,

} from "react-bootstrap";
import InstrumentCard from "./InstrumentCard";



const InstrumentSetup = () => {
  const { currentUser } = useAuth();
  const userId = currentUser.uid;
  const history = useHistory();



  
  const handleClick = () => {
    const listOfInstruments = [];
    state.forEach((instrument) => {
      if (instrument.checked) {
        listOfInstruments.push(instrument.id);
      }
    });

    db.collection("users")
      .doc(userId)
      .set({ instrumentsPlayed: listOfInstruments }, { merge: true })
      .then(() => history.push("/profile-setup/avatar"))
      .catch(() => {
        alert(
          "Something went wrong... Please contact one of the Indigo Admins for assistance."
        );
      });
  };


  function reducer(state, action) {
    switch (action.type) {
      case "select":
        const list = state.map((instrument) =>
        instrument.id === action.instrument.id
            ? { ...instrument, checked: !instrument.checked }
            : instrument
        );
        return list;
      default:
        alert("Sorry! Something went wrong... Please contact one of the Indigo Admins for assistance.")
    } 
  }


  const [state, dispatch] = useReducer(reducer, instrumentChoices);


  return (
    <Card>
      <Card.Body>
      <h3 className="mt-3">What instruments do you play?</h3>
          <Container>
            <Row>
              {state.map((instrument) => (
                <Col xs="3" className="mt-3">
                  <InstrumentCard instrument={instrument} dispatch={dispatch} />
                </Col>
              ))}
            </Row>
            <Button className="mt-3 float-right" onClick={handleClick}>
              Next
            </Button>
          </Container>
      </Card.Body>
    </Card>
  );
};

const CheckboxSection = ({ values, isSubmitting, handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <FieldArray
      name="instrumentsPlayed"
      render={(arrayHelpers) => (
        <Form.Group>
          <Form.Label>
            <h3>What Instruments do you play?</h3>
          </Form.Label>
          <Form.Row>
            {instrumentChoices.map((instrument) => (
              <Col key={instrument.id}>
                <Form.Check
                  inline
                  className="mb-3"
                  label={instrument.name}
                  style={{}}
                  name="instrumentsPlayed"
                  type="checkbox"
                  value={instrument.id}
                  checked={values.instrumentsPlayed.includes(instrument.id)}
                  onChange={(e) => {
                    if (e.target.checked) arrayHelpers.push(instrument.id);
                    else {
                      const idx = values.instrumentsPlayed.indexOf(instrument.id);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
              </Col>
            ))}
          </Form.Row>
          <Button className="float-right" variant="primary" type="submit" disabled={isSubmitting}>
            Next
          </Button>
        </Form.Group>
      )}
    />
  </Form>
);

/*

<Card>
        <Card.Body>
          <h2 className="text-center mb-4">Tell us more</h2>
          <Formik
            initialValues={{ selectedInstruments: [] }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const { email, password, passwordConfirm } = values;
              alert(JSON.stringify(values, null, 2))
              try {
              } catch {}
            }}
          >
            {(formik) => (
              <>
                {console.log(formik)}
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group controlId="instrument">
                    
                    <Row>
                      {instruments.map((instrument) => (
                        <Col
                          key={`custom-inline-${instrument}`}
                          className="mb-3"
                        >
                          <Form.Check
                            inline
                            label={instrument}
                            type="checkbox"
                            name="selectedInstruments"
                            value={`${instrument}`}
                            id={`${instrument}-checkbox`}
                            onChange={formik.handleChange}
                            {...formik.getFieldProps("selectedInstruments")}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Form.Group>
                  /* 
                  <Form.Group>
                    <Form.Label>Upload a Profile Photo</Form.Label>
                    <Form.File id="profilePhoto" />
                  </Form.Group> 

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    Submit
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        </Card.Body>
      </Card>

      */

export default InstrumentSetup;
