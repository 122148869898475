import React from 'react'
import { ListGroup } from "react-bootstrap";

const GenreItem = ({genres}) => {
    return (
        <div>
        {genres.map((genre) => (
          <ListGroup.Item key={genre}>{genre}</ListGroup.Item>
        ))}
      </div>
    )
}

export default GenreItem
