let checked = false;

export let genres = [
  { id: "Jazz", name: "Jazz", checked },
  { id: "Pop", name: "Pop", checked },
  { id: "Metal", name: "Metal", checked },
  { id: "Oldies", name: "Oldies", checked },
  { id: "R&b", name: "R&b", checked },
  { id: "Rap", name: "Rap", checked },
  { id: "Reggae", name: "Reggae", checked },
  { id: "Rock", name: "Rock", checked },
  { id: "80s", name: "80s", checked },
  { id: "90s", name: "90s", checked },
  { id: "Blues", name: "Blues", checked },
  { id: "Classic", name: "Classic", checked },
  { id: "Country", name: "Country", checked },
  { id: "Electronic", name: "Electronic", checked },
  { id: "Folk", name: "Folk", checked },
  { id: "Latin", name: "Latin", checked },
];
