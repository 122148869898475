import React, { useState } from "react";
import {
  Card,
  Form,
  Button,
  Alert,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import registerSchema from "../../schemas/registerSchema";
import { db } from "../../firebase";
import { Formik } from "formik";
import { getYear } from "date-fns";

const Signup = () => {
  const [error, setError] = useState("");
  const history = useHistory();

  const { signup } = useAuth(); // We are destructuring the signup function from AuthContext

  return (
    <div>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Sign Up</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Formik
            initialValues={{
              name: "",
              email: "",
              password: "",
              passwordConfirm: "",
              telegramHandle: "",
            }}
            validationSchema={registerSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const { name, email, password, telegramHandle } = values;

              try {
                setError("");
                // Sign up the user and add name to Database
                await signup(email, password)
                  .then(function (user) {
                    const uid = user.user.uid;
                    var dateCreated = new Date();
                    var currentYear = getYear(dateCreated);
                    db.collection("users")
                      .doc(uid)
                      .set(
                        {
                          uid,
                          name,
                          email,
                          telegramHandle,
                          isAdmin: false,
                          dateCreated,
                          xpPoints: { [currentYear]: 0 },
                          eventsAttended: [],
                          eventsUpcoming: {},
                        },
                        { merge: "true" }
                      )
                      .then(() => {
                        setSubmitting(false);
                        resetForm();

                        history.push("/profile-setup/genre");
                      })
                      .catch(() => {
                        setError("Failed to sign up");
                      });
                  })
                  .catch(() => {
                    setError("Failed to sign up");
                  });

                // await Promise.all([signup(email,password) , addName(name)])
              } catch {
                setError("Failed to sign up");
              }
            }}
          >
            {(formik) => (
              <>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group controlId="formName">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="What should we call you?"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <Alert variant="danger">{formik.errors.name}</Alert>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="formTeleHandle">
                    <Form.Label>Telegram Handle:</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>@</InputGroup.Text>
                        <Form.Control
                          type="text"
                          name="telegramHandle"
                          placeholder="Telegram Handle"
                          {...formik.getFieldProps("telegramHandle")}
                        />
                      </InputGroup.Prepend>
                      {formik.touched.telegramHandle &&
                      formik.errors.telegramHandle ? (
                        <Alert variant="danger">
                          {formik.errors.telegramHandle}
                        </Alert>
                      ) : null}
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId="formEmail">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="How should we keep in touch?"
                      {...formik.getFieldProps("email")}
                    />

                    {formik.touched.email && formik.errors.email ? (
                      <Alert variant="danger">{formik.errors.email}</Alert>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="formPassword">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Type your password"
                      {...formik.getFieldProps("password")}
                    />
                    <Form.Text id="passwordHelpBlock" muted>
                      Must Contain 8 Characters, One Uppercase, One Lowercase,
                      One Number and one special case Character
                    </Form.Text>

                    {formik.touched.password && formik.errors.password ? (
                      <Alert variant="danger">{formik.errors.password}</Alert>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="formPasswordConfirm">
                    <Form.Label>Confirm Password:</Form.Label>
                    <Form.Control
                      type="password"
                      name="passwordConfirm"
                      placeholder="Confirm your password"
                      {...formik.getFieldProps("passwordConfirm")}
                    />
                    {formik.touched.passwordConfirm &&
                    formik.errors.passwordConfirm ? (
                      <Alert variant="danger">Passwords do not match</Alert>
                    ) : null}
                  </Form.Group>

                  <div className="d-flex justify-content-center">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      Setup my Profile{" "}
                      {formik.isSubmitting && (
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <div className="w-100 text-center mt-2">
        Already have an account? <Link to="/login">Log In</Link>
      </div>
    </div>
  );
};

export default Signup;

/*
<div>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Sign Up</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required />
            </Form.Group>

            <Button disabled={loading} type="submit" className="w-100">
              Sign Up
            </Button>
          </Form>
        </Card.Body>
      </Card>

      <div className="w-100 text-center mt-2">
        Already have an account? <Link to="/login">Log In</Link>
      </div>
    </div>

    */
