import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const AddRuleModal = ({ show, handleClose, addRule }) => {
  const [rule, setRule] = useState("");

  return (
    <div>
      <Modal
      autoFocus = {false}
        centered
        show={show}
        handleClose={() => {
          setRule("");
          handleClose();
        }}
      >
        <Modal.Header>
          <Modal.Title>Add a rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Control
              autoFocus={true}
                type="text"
                placeholder="Eg 'Users must communicate by hissing'"
                onChange={(e) => {
                  setRule(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
          <div className='float-right'>
            <Button variant="secondary" onClick={() => handleClose()}>
              Cancel
            </Button>

            <Button className='ml-3' onClick={() => addRule(rule)}>Add Rule</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddRuleModal;
