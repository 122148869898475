import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { db } from "../../firebase";
import EventThumbnail from "./EventThumbnail";
import { useAuth } from "../../contexts/AuthContext";

import CreateEventModal from "./CreateEventModal";
import ViewEventModal from "./ViewEventModal";
const EventsContainer = () => {
  const {  isAdmin } = useAuth();

  const eventsRef = db.collection("events");
  const [listOfEvents, setListOfEvents] = useState();
  const [showCreateEventModal, setCreateEventModal] = useState(false);
  const [showViewEventModal, setViewEventModal] = useState(false);
  const [viewModalContent, setViewModalContent] = useState();
  const handleViewClose = () => setViewEventModal(false);
  const handleViewShow = () => setViewEventModal(true);

  const handleCreateClose = () => setCreateEventModal(false);
  const handleCreateShow = () => setCreateEventModal(true);

  const triggerViewPopup = (data, id) => {
    handleViewShow();
    setViewModalContent({...data, id});
  };

  const deleteEvent = (id) => {
    db.collection("events")
      .doc(id)
      .delete()
      .then(() => {
        handleViewClose()

      })
      .catch(() => {
        alert("Error deleting event");
      });
  };

  useEffect(() => {
    const unsubscribe = () => {
      eventsRef.onSnapshot((snap) => {
        setListOfEvents(snap.docs);

  
      });
    };

    unsubscribe();
    return () => {
      unsubscribe();
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      {isAdmin && <div className="my-3">
        <Button onClick={() => handleCreateShow()}>Add an event</Button>
      </div>}
      <Row>
        {listOfEvents &&
          listOfEvents.map((event) => {
            return (
              <Col key={event.id} className="col-lg-4 d-flex align-items-stretch" xs={12} sm={12} md={4}>
                <EventThumbnail
                  id = {event.id}
                  data={event.data()}
                  triggerViewPopup={triggerViewPopup}
                />
              </Col>
            );
          })}
      </Row>

      <CreateEventModal
        show={showCreateEventModal}
        handleClose={handleCreateClose}
      />

      <ViewEventModal
        deleteEvent={deleteEvent}
        data={viewModalContent}
        show={showViewEventModal}
        handleClose={handleViewClose}
      />
    </Container>
  );
};

export default EventsContainer;
