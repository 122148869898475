import React, { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { db } from "../../firebase";
import Post from "./Post";
import { useAuth } from "../../contexts/AuthContext";

const FeedStream = () => {
  // const { currentUser } = useAuth();

  const { isAdmin } = useAuth();
  const [isUserAdmin, setIsUserAdmin] = useState(true);

  const [showAlert, setShowAlert] = useState(false);
  const [posts, setPosts] = useState();
  const postRef = db.collection("posts");
  const [searchTag, setSearchTag] = useState("");
  const [filteredPosts, setFilteredPosts] = useState();
  useEffect(() => {
    setIsUserAdmin(isAdmin);
    // eslint-disable-next-line
  }, [isAdmin]);

  function deletePost(id) {
    postRef
      .doc(id)
      .delete()
      .then((response) => {
        const newPosts = posts.filter((post) => post.id !== id);
        setPosts(newPosts);

        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);

        // setShowToast(true);
        // setTimeout(() => {
        //   setShowToast(false);
        // }, 2000);
      });
  }

  useEffect(() => {
    const unsubscribe = () => {
      postRef.orderBy("datePosted", "desc").onSnapshot((snap) => {
        setPosts(snap.docs);
      });
    };

    unsubscribe();

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setSearchTag(e.target.value);

    if (e.target.value) {
      const filteredPosts = posts.filter((post) => {
        console.log(post.data().postTag);
        return post.data().postTag === e.target.value;
      });

      setFilteredPosts(filteredPosts);
      console.log("Search Tag", e.target.value);
      console.log("Filtered posts", filteredPosts);
    } else {
      setFilteredPosts("");
    }
  };

  return (
    <>
      <div>
        {showAlert && (
          <Alert variant="success">
            <Alert.Heading>Post Deleted!</Alert.Heading>
          </Alert>
        )}
      </div>

      <hr style={{ height: "1px", backgroundColor: "#333" }} />
      <h1>Your Feed</h1>

      <div className="mt-3">
        <Form onSubmit={handleSearch}>
          <Form.Label>
            <h5>Filter Post By Tag:</h5>
          </Form.Label>
          <Form.Control
            as="select"
            name="postTag"
            defaultValue="Choose..."
            onChange={handleChange}
          >
            <option value="">No Filter</option>
            <option value="casual">Casual</option>
            <option value="announcement">Announcement</option>
            <option value="connect">Looking to connect!</option>
            <option value="shopping">Sell/Buy</option>
          </Form.Control>
        </Form>
      </div>

      <div>
        {filteredPosts ? (
          filteredPosts.length > 0 ? (
            filteredPosts.map((doc) => {
              return (
                <Post
                  key={doc.id}
                  postId={doc.id}
                  postTitle={doc.data().postTitle}
                  postContent={doc.data().postContent}
                  postedByUserId={doc.data().postedByUserId}
                  imageUrl={doc.data().postImageUrl}
                  arrayOfLikes={doc.data().arrayOfLikes}
                  postDate={doc.data().datePosted}
                  postTag={doc.data().postTag}
                  deletePost={deletePost}
                  deleteRights={isUserAdmin}
                />
              );
            })
          ) : (
            <Alert variant="secondary" className="text-center mt-3">
              <Alert.Heading>No Posts Yet!</Alert.Heading>
              <p>Maybe you should make one :)</p>
            </Alert>
          )
        ) : posts ? (
          posts.map((doc) => {
            return (
              <Post
                key={doc.id}
                postId={doc.id}
                postTitle={doc.data().postTitle}
                postContent={doc.data().postContent}
                postedByUserId={doc.data().postedByUserId}
                imageUrl={doc.data().postImageUrl}
                arrayOfLikes={doc.data().arrayOfLikes}
                postDate={doc.data().datePosted}
                postTag={doc.data().postTag}
                deletePost={deletePost}
                deleteRights={isUserAdmin}
              />
            );
          })
        ) : (
          <Alert variant="secondary" className="text-center mt-3">
            <Alert.Heading>No Posts Yet!</Alert.Heading>
            <p>Maybe you should make one :)</p>
          </Alert>
        )}
      </div>
    </>
  );
};

export default FeedStream;
