import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import ForgotPassword from "../../components/AuthCards/ForgotPassword";

const ForgotPasswordPage = () => {
  return (
    <Container>
      <Row>
        <Col className="my-auto ">
          <h1>Forgot your password?</h1>
          <p>No worries. Type in your email and we'll help you reset it.</p>
        </Col>

        <Col>
          <ForgotPassword />
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPasswordPage;
