import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import { enUS } from "date-fns/locale";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { GapiFunctions } from "./GapiFunctions";
import DatePopupModal from "./DatePopupModal";
import { Container, Button, Alert, Spinner } from "react-bootstrap";
import { getId } from "./bookingCalendarId";
import CreateBookingPopupModal from "./CreateBookingPopupModal";
import moment from "moment";

const bookingCalendarId = getId();

const locales = {
  "en-US": enUS,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => {
    startOfWeek(new Date(), { weekStartsOn: 1 });
  },
  getDay,
  locales,
});

// ***********************************
// const customComponents = {
//   dateCellWrapper: (dateCellWrapperProps) => {
//     // Show 'click me' text in arbitrary places by using the range prop
//     const hasAlert = dateCellWrapperProps.range
//       ? dateCellWrapperProps.range.some((date) => {
//           return date.getDate() % 12 === 0;
//         })
//       : false;

//     const style = {
//       display: "flex",
//       flex: 1,
//       borderLeft: "1px solid #DDD",
//       backgroundColor: hasAlert ? "#f5f5dc" : "#fff",
//     };
//     return (
//       <div style={style}>
//         {hasAlert && (
//           <a onClick={console.log("custom dateCellWrapper component clicked")}>
//             Click me
//           </a>
//         )}
//         {dateCellWrapperProps.children}
//       </div>
//     );
//   },
// };

// ***********************************

const JamRoomCalendar = () => {
  var gapi = window.gapi;
  const gapiInstance = new GapiFunctions(gapi);

  //   ! TO-DO MOVE TO ENV
  const CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID;

  const [events, setEvents] = useState();
  const [loading, setLoading] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [eventInfo, setEventInfo] = useState();

  // * To show any errors
  const [error, setError] = useState(false);

  // * States to handle the showing of VIEW and CREATION modals
  const [show, setShow] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  // * Functions to handle the showing and closing of the VIEW modal
  const handleViewClose = () => setShow(false);
  const handleViewShow = () => setShow(true);

  // * Functions to handle the showing and closing of the CREATION modal

  const handleCreateClose = () => setShowCreateModal(false);
  const handleCreateShow = () => setShowCreateModal(true);

  useEffect(() => {
    // console.log("useeffect");
    gapi.load("client:auth2", function () {
      gapi.auth2.init({ client_id: CLIENT_ID }).then(() => {
        gapiInstance.loadClient().then(() => {
          var auth2 = gapi.auth2.getAuthInstance();
          if (auth2.isSignedIn.get()) {
            // * If user is already logged in , fetch the data from the calendar and render conditionally
            execute();
          }
        });
      });
    });
    // eslint-disable-next-line
  }, []);

  /* 
  Function to fetch booking events from the Google Calendar
  */
  function execute() {
    setLoading(true);
    return (
      gapi.client.calendar.events
        .list({
          calendarId: bookingCalendarId,
        })
        // * Load the events from the calendar
        .then(
          function (response) {
            const tempArray = [];
            
            // * Handle the results here (response.result has the parsed body).
            // eslint-disable-next-line
            JSON.parse(response.body).items.map((event) => {
              let start, end;
              if (event.start.date) {
                start = new Date(event.start.date);
              }

              if (event.start.dateTime) {
                start = new Date(event.start.dateTime);
              }

              if (event.end.date) {
                end = new Date(event.end.date);
              }

              if (event.end.dateTime) {
                end = new Date(event.end.dateTime);
              }

              tempArray.push({
                start: start,
                end: end,
                title: event.summary,
                email: event.creator.email,
                id: event.id,
              });
            });

            var user = gapi.auth2.getAuthInstance().currentUser.get();
            var profile = user.getBasicProfile();
            setUserInfo(profile);

            // Update Login Status
            setLoggedIn(true);

            // Update the events loaded
            setEvents(tempArray);

            // Set loading to false
            setLoading(false);
          },
          //! IF the user doesnt have access to the calendar
          function (err) {
            setError(true);
            setLoggedIn(true);
            setLoading(false);
            setEvents([]);
            //   alert("You do not have access")
          }
        )
    );
  }

  /* 
  Function to manage clicking of empty slot (to create event) 
  */
  function handleCreatePopup(e) {
    setEventInfo(e);
    handleCreateShow();
  }

  /* Function to manage creation of events */
  function handleCreateBooking(title, start, end, startTime, endTime) {

    if (typeof startTime === "string") {
      // Then we need to do formatting
      // We need to concatenate the date (date object) and the time (string) together

      const momentStartDate = moment(start);
      const formattedStartDate = momentStartDate.format("YYYY-MM-DD");

      var startDateTime = parse(
        formattedStartDate + " " + startTime,
        "yyyy-MM-dd kk:mm",
        new Date()
      );

      startTime = startDateTime;
    }

    if (typeof endTime === "string") {
      const momentEndDate = moment(end);
      const formattedEndDate = momentEndDate.format("YYYY-MM-DD");

      var endDateTime = parse(
        formattedEndDate + " " + endTime,
        "yyyy-MM-dd kk:mm",
        new Date()
      );
      endTime = endDateTime;

    }

    var event = {
      summary: title,
      start: {
        dateTime: startTime,
      },
      end: {
        dateTime: endTime,
      },
    };
    handleCreateClose();
    return gapi.client.calendar.events
      .insert({
        calendarId: bookingCalendarId,
        resource: event,
      })
      .then(() => {
        execute();
        alert("Booking created successfully");
      })
      .catch((e) => {
        console.error(e);
        alert(
          "Something went wrong with your booking... Please contact one of the Indigo Admins for assistance."
        );
      });
  }

  /* Function to handle deletion of events */
  function handleDelete(title, id) {
    return gapi.client.calendar.events
      .delete({
        calendarId: bookingCalendarId,
        eventId: id,
      })
      .then(() => {
        handleViewClose();
        alert(`${title} booking deleted successfully`);
        execute();
      })
      .catch((e) => {
        alert(
          "Something went wrong with deleting your booking... Please contact one of the Indigo Admins for assistance."
        );
        console.error(e);
      });
  }

  /* 
  Function to manage viewing of current events
  */

  function handleViewPopup(e) {
    setEventInfo(e);
    handleViewShow();
  }

  return (
    <Container fluid>
      <span>
        {!loggedIn ? (
          <Button
            className="mt-3"
            onClick={() => {
              gapiInstance
                .authenticate(setLoading)
                .then(() => execute())
                .then(gapiInstance.loadClient());
            }}
          >
            Login with your Google Account
          </Button>
        ) : (
          <Button
            variant="secondary"
            className="justify-content-center"
            onClick={() => {
              gapiInstance.logout().then(() => {
                window.location.reload(false);
              });
            }}
          >
            Logout of Google Account
          </Button>
        )}

        {loggedIn && userInfo ? (
          <h4
            style={{ textAlign: "right", display: "inline-block" }}
            className="float-right"
          >
            Logged In as {userInfo.getEmail()}
          </h4>
        ) : null}
      </span>

      {error && (
        <Alert className="mt-3" variant="warning">
          <Alert.Heading>
            Your Google account does not have access to the booking calendar.
          </Alert.Heading>
          <p>You won't be able to see or make bookings.</p>
          <p>
            Please contact Trevor at @trevorsingsterribly on Telegram for
            assistance to give you access.
          </p>
        </Alert>
      )}

      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : null}

      {!error && events ? (
        <Calendar
          selectable
          culture="en-SG"
          views={{ month: true, week: true, day: true }}
          localizer={localizer}
          className="jam-room-calendar overflow-scroll mt-3"
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 700 }}
          onSelectSlot={(event) => handleCreatePopup(event)}
          onSelectEvent={(event) => handleViewPopup(event)}
        />
      ) : null}

      {eventInfo && (
        <DatePopupModal
          show={show}
          handleClose={handleViewClose}
          handleDelete={handleDelete}
          eventInfo={eventInfo}
        />
      )}

      {eventInfo && (
        <CreateBookingPopupModal
          show={showCreateModal}
          handleClose={handleCreateClose}
          handleCreateBooking={handleCreateBooking}
          dateInfo={eventInfo}
        />
      )}
    </Container>
  );
};

export default JamRoomCalendar;
