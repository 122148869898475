import "./App.css";
import { Container } from "react-bootstrap";

import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import NavigationBar from "./NavigationBar";
import PrivateRoute from "./PrivateRoute";

// * Profile Pages
import Profile from "./ProfileComps/Profile";
import ExternalProfile from "./ProfileComps/ExternalProfile";

// * Auth Pages
import LoginPage from "../pages/AuthPages/LoginPage";
import SignupPage from "../pages/AuthPages/SignupPage";
import GenreSetupPage from "../pages/AuthPages/GenreSetupPage";
import BioSetupPage from "../pages/AuthPages/BioSetupPage";
import ForgotPasswordPage from "../pages/AuthPages/ForgotPasswordPage";
import AvatarSetupPage from "../pages/AuthPages/AvatarSetupPage";
import InstrumentSetupPage from "../pages/AuthPages/InstrumentSetupPage";
import UpdateProfilePage from "../pages/AuthPages/UpdateProfilePage";

// * Manage Members Page
import ManageMembersPage from "../pages/MemberManagementPages/ManageMembersPage";

// * Feed Page
import FeedPage from "../pages/FeedPages/FeedPage";

// * Jam Room Page
import JamRoomPage from "../pages/JamRoomPages/JamRoomPage";

// * Home Page
import HomePage from "../pages/HomePage";

//! TEST IMPORTS

import UploadData from "./Testing/UploadData";
import UploadImage from "./Testing/UploadImage";
import AvatarTesting from "./Testing/AvatarTesting";
import PrintUser from "./Testing/PrintUser";
import TestAdmin from "./Testing/TestAdmin";
import { TestDate } from "./Testing/TestDate";
import AboutUsPage from "../pages/AboutUsPages/AboutUsPage";
import EventsPage from "../pages/EventsPages/EventsPage";

import Footer from "./Footer";

function App() {
  return (
    <>
      <Container className="main-container" fluid>
        <Router>
          <AuthProvider>
            <NavigationBar />
            <Switch>
              <PrivateRoute exact path="/users/me" component={Profile} />
              <PrivateRoute
                exact
                path="/users/:id"
                component={ExternalProfile}
              />
              <PrivateRoute
                path="/profile-setup/instrument"
                component={InstrumentSetupPage}
              />
              <PrivateRoute
                path="/profile-setup/genre"
                component={GenreSetupPage}
              />
              <PrivateRoute
                path="/profile-setup/bio"
                component={BioSetupPage}
              />
              <PrivateRoute
                path="/profile-setup/avatar"
                component={AvatarSetupPage}
              />
              <PrivateRoute
                exact
                path="/update-profile"
                component={UpdateProfilePage}
              />
              <PrivateRoute exact path="/feed" component={FeedPage} />
              <PrivateRoute exact path="/jamroom" component={JamRoomPage} />
              <PrivateRoute
                exact
                path="/manage-members"
                component={ManageMembersPage}
              />

              {/* PUBLIC ROUTES */}
              <Route exact path="/" component={HomePage} />
              <Route exact path="/about-us" component={AboutUsPage} />
              <Route exact path="/events" component={EventsPage} />
              <Route path="/signup" component={SignupPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/forgot-password" component={ForgotPasswordPage} />

              {/*! TEST ROUTES */}
              <Route exact path="/test-upload" component={UploadData} />
              <Route exact path="/test-image-upload" component={UploadImage} />
              <Route exact path="/test-avatar" component={AvatarTesting} />
              <Route exact path="/test-print-user" component={PrintUser} />
              <Route exact path="/test-admin" component={TestAdmin} />
              <Route exact path="/test-date" component={TestDate} />
            </Switch>
          </AuthProvider>
        </Router>
        {/* <Footer /> */}
      </Container>
    </>
  );
}

export default App;
