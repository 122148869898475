import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import jazzImage from "../../assets/jazz.png";

const GenreCard = ({ genre, dispatch }) => {
  // const images = require.context('../../assets/genre-assets/', true);
  // loadImage = imageName => (assets(`./${imageName}`).default);
  const imageFileName = genre.id.charAt(0).toLowerCase() + genre.id.slice(1);
  // console.log(imageFileName)

  // const image = require('../../assets/genre-assets/' + 'genre-' +  imageFileName + '.png');
  const [image, setImage] = useState();
  const loadImage = (imageName) => {
    import("../../assets/genre-assets/svg/" + "genre-" + imageName + ".svg").then(
      (image) => {
        console.log(image);
        setImage(image.default);
      }
    );
  };

  useEffect(() => {
    const imageFileName = genre.id.charAt(0).toLowerCase() + genre.id.slice(1);

    loadImage(imageFileName);
  }, []);

  // const image = '../../assets/genre-assets/' + 'genre-' +  imageFileName + '.png';
  return (
    <Card
      className="genre-card"
      onClick={() => dispatch({ type: "select", genre })}
      bg={genre.checked ? "info" : null}
    >
      <Card.Body className="text-center" key={genre.id}>
        <Card.Img variant="bottom" src={image} height={100} />
        <Card.Title className="mt-3">{genre.id}</Card.Title>
      </Card.Body>
    </Card>
  );
};

export default GenreCard;
