import moment from "moment";

// ! TO-DO: MOVE TO ENV
const API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;

export class GapiFunctions {
  constructor(gapi) {
    if (!GapiFunctions.instance) {
      this.gapi = gapi;
      this.bookings = [];
      GapiFunctions.instance = this;
    }

    return GapiFunctions.instance;
  }

  authenticate(setLoading) {
    setLoading(true);
    console.log("authenticating");
    return this.gapi.auth2
      .getAuthInstance()
      .signIn({
        scope:
          // "https://www.googleapis.com/auth/calendar 
          "https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.readonly",
          // "https://www.googleapis.com/auth/calendar.app.created https://www.googleapis.com/auth/calendar.calendarlist.readonly",
      
        })
      .then(
        function () {
          console.log("Sign-in successful");
        },
        function (err) {
          setLoading(false);
          alert("Sorry something went wrong...");
          console.error("Error signing in", err);
        }
      );
  }

  loadClient() {
    this.gapi.client.setApiKey(API_KEY);
    return (
      this.gapi.client
        //   .load("https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest")
        .load("calendar", "v3")
        .then(
          function () {
            // console.log("GAPI client loaded for API");
          },
          function (err) {
            // console.error("Error loading GAPI client for API", err.message);
          }
        )
    );
  }

  execute() {
    return this.gapi.client.calendar.events
      .list({
        calendarId: "vfeldhnkgkci8n1drquo5qcfn0@group.calendar.google.com",
      })
      .then(
        function (response) {
          const tempArray = [];
          // Handle the results here (response.result has the parsed body).
          // eslint-disable-next-line
          JSON.parse(response.body).items.map((event) => {
            let start;
            if (event.start.date) {
              start = moment(event.start.date).toDate();
              console.log("start");
              console.log(typeof start);
            }

            if (event.start.dateTime) {
              start = moment(event.start.date).toDate();
              console.log("start");
              console.log(typeof start);
            }

            console.log("end");
            console.log(typeof event.end.date);
            tempArray.push({
              start: start,
              end:
                moment(event.end.date).toDate() ||
                moment(event.end.dateTime).toDate(),
              title: event.summary,
            });
          });

          console.log("temparray", tempArray);
          return tempArray;
        },
        function (err) {
          console.error("Execute error", err);
        }
      );
  }

  logout() {
    return this.gapi.auth2.getAuthInstance().signOut();
  }
}
