let checked = false;

export const instrumentChoices = [
  { id: "Saxophone", name: "Saxophone" , checked},
  { id: "Sing", name: "Sing", checked },
  { id: "Trumpet", name: "Trumpet" , checked},
  { id: "Violin", name: "Violin", checked },
  { id: "Xylophone", name: "Xylophone" , checked},
  { id: "Acoustic-guitar", name: "Acoustic Guitar", checked },
  { id: "Bass-guitar", name: "Bass Guitar", checked },
  { id: "Cello", name: "Cello", checked },
  { id: "Dj", name: "Dj", checked },
  { id: "Double-bass", name: "Double Bass", checked },
  { id: "Drums", name: "Drums", checked },
  { id: "Electric-guitar", name: "Electric Guitar", checked },
  { id: "Harp", name: "Harp", checked },
  { id: "Keyboard", name: "Keyboard" , checked},
  { id: "Piano", name: "Piano", checked },
];
