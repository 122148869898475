import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import TimePicker from "react-time-picker";
import format from "date-fns/format";

const CreateBookingPopupModal = ({
  show,
  handleClose,
  handleCreateBooking,
  dateInfo,
}) => {
  const [startValue, setStartValue] = useState();
  const [endValue, setEndValue] = useState();
  const [title, setTitle] = useState("");

  // * Extract the date that the box clicked is in
  const { start, slots, end } = dateInfo;
  useEffect(() => {
    if (slots) {
      setStartValue(slots[0]);
      setEndValue(slots[slots.length - 1]);
      //   console.log(formatRFC3339(startValue))
    }
  }, [slots]);

  return (
    <div>
      <Modal
        centered
        show={show}
        handleClose={() => {
          setTitle("");
          handleClose();
        }}
      >
        <Modal.Header>
          <Modal.Title>Create a booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{format(start, "PP eeee")}</h4>

          <Form>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Band Name/Your Name + Contact Number"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <Form.Text className="text-muted">
                Please take care of the Jam Room during your session
              </Form.Text>
            </Form.Group>
          </Form>
          <TimePicker
            autoFocus={true}
            onChange={(e) => {
              console.log(e);

              setStartValue(e);
            }}
            value={startValue}
            disableClock={true}
          />

          <TimePicker
            autoFocus={true}
            onChange={(e) => {
              setEndValue(e);
            }}
            value={endValue}
            disableClock={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setTitle("");
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleCreateBooking(title, start, end, startValue, endValue);
            }}
          >
            Create Booking
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateBookingPopupModal;
