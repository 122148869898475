import React from "react";
import { Button } from "react-bootstrap";
// import { useAuth } from "../../contexts/AuthContext"


const UserInteraction = ({ postId, isLiked, handleLike, handleUnlike }) => {
    // const {currentUser} = useAuth();
   

  return (
    <div>
      {isLiked ? (
        <Button size="sm" onClick={() => handleUnlike(postId)} variant="primary">👍 Liked</Button>
      ) : (
        <Button size="sm" onClick={() => handleLike(postId)} variant="outline-primary">👍 Like</Button>
      )}
    </div>
  );
};

export default UserInteraction;
