import React from "react";
import JamRoomCalendar from "../../components/JamRoomComp/JamRoomCalendar";
import {  Container, Row, Col } from "react-bootstrap";
import JamRoomRulesCard from "../../components/JamRoomComp/JamRoomRulesCard";
import JamRoomHelpCard from "../../components/JamRoomComp/JamRoomHelpCard";
import "./jamroom.css"
const JamRoomPage = () => {
  return (
    <Container fluid>
      <Container fluid>
      <h1>SCAPE Jam Room Booking</h1>
        <Row>
          <Col xs={9}>
            <JamRoomCalendar />
          </Col>
          <Col>
          <JamRoomHelpCard/>
            <JamRoomRulesCard />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default JamRoomPage;
